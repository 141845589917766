import { getScrollbarSize } from "../utils/getScrollbarSize";
import ownerDocument from "../utils/ownerDocument";
import ownerWindow from "../utils/ownerWindow";
import { ModalManagerClass } from "./ModalManagerTypes";

/* Checks if the given container element is overflowing. */
function isOverflowing(container: HTMLElement): boolean {
  const doc = ownerDocument(container);

  if (doc.body === container) {
    return ownerWindow(doc).innerWidth > doc.documentElement.clientWidth;
  }

  return container.scrollHeight > container.clientHeight;
}

/* Sets or removes the 'aria-hidden' attribute on the given HTML element. */
export function ariaHidden(node: HTMLElement, show: boolean) {
  if (show) {
    node.setAttribute("aria-hidden", "true");
  } else {
    node.removeAttribute("aria-hidden");
  }
}

/* Returns the padding-right value of the given HTML element. */
function getPaddingRight(node: HTMLElement): number {
  return parseInt(window.getComputedStyle(node)["padding-right"], 10) || 0;
}

/* Sets or removes the 'aria-hidden' attribute on all sibling elements of the specified nodes within a container. */
function ariaHiddenSiblings(
  container: HTMLElement,
  mountNode: HTMLElement,
  currentNode: HTMLElement,
  nodesToExclude: HTMLElement[] = [],
  show: boolean
) {
  const blacklist = [mountNode, currentNode, ...nodesToExclude];
  const blacklistTagNames = ["TEMPLATE", "SCRIPT", "STYLE"];
  Array.from(container.children).forEach(node => {
    if (
      node.nodeType === 1 &&
      !blacklist.includes(node as HTMLElement) &&
      !blacklistTagNames.includes(node.tagName)
    ) {
      ariaHidden(node as HTMLElement, show);
    }
  });
}

/* Returns the index of the first element in the given array that satisfies the provided callback function. */
function findIndexOf<T>(
  containerInfo: T[],
  callback: (item: T) => boolean
): number {
  let idx = -1;
  containerInfo.some((item, index) => {
    if (callback(item)) {
      idx = index;
      return true;
    }
    return false;
  });
  return idx;
}

/* Adjusts the styles of a container element to handle scroll locking when a modal is opened. */
function handleContainer(containerInfo: any, props: any) {
  const restoreStyle: { value: string; key: string; el: HTMLElement }[] = [];
  const restorePaddings: string[] = [];
  const container = containerInfo.container;
  let fixedNodes: NodeListOf<HTMLElement>;

  if (!props.disableScrollLock) {
    if (isOverflowing(container)) {
      // Compute the size before applying overflow hidden to avoid any scroll jumps.
      const scrollbarSize = getScrollbarSize();

      // Store the original style properties to restore when the modal is closed.
      restoreStyle.push({
        value: container.style.paddingRight,
        key: "padding-right",
        el: container
      });

      // Adjust the padding-right to account for the scrollbar size
      container.style.paddingRight = `${
        getPaddingRight(container) + scrollbarSize
      }px`;

      // Find all fixed elements and adjust their padding-right
      fixedNodes = ownerDocument(container).querySelectorAll(".mui-fixed");
      Array.from(fixedNodes).forEach(node => {
        restorePaddings.push(node.style.paddingRight);
        node.style.paddingRight = `${getPaddingRight(node) + scrollbarSize}px`;
      });
    }

    // Determine the appropriate scroll container based on the parent element's node name and overflow style.
    const parent = container.parentElement;
    const scrollContainer =
      parent.nodeName === "HTML" &&
      window.getComputedStyle(parent)["overflow-y"] === "scroll"
        ? parent
        : container;

    restoreStyle.push({
      value: scrollContainer.style.overflow,
      key: "overflow",
      el: scrollContainer
    });
    scrollContainer.style.overflow = "hidden";
  }

  // Function to restore the original styles and paddings
  const restore = () => {
    if (fixedNodes) {
      Array.from(fixedNodes).forEach((node, i) => {
        if (restorePaddings[i]) {
          node.style.paddingRight = restorePaddings[i];
        } else {
          node.style.removeProperty("padding-right");
        }
      });
    }

    restoreStyle.forEach(({ value, el, key }) => {
      if (value) {
        el.style.setProperty(key, value);
      } else {
        el.style.removeProperty(key);
      }
    });
  };

  return restore;
}

/* Returns an array of all the hidden sibling elements of the given container element. */
function getHiddenSiblings(container: HTMLElement): HTMLElement[] {
  const hiddenSiblings: HTMLElement[] = [];
  Array.from(container.children).forEach(node => {
    if (node.getAttribute && node.getAttribute("aria-hidden") === "true") {
      hiddenSiblings.push(node as HTMLElement);
    }
  });
  return hiddenSiblings;
}

/**
 * The ModalManager class handles adding, mounting, and removing modals, manages scroll locking, and ensures proper accessibility by adjusting aria-hidden attributes for sibling elements.
 */
class ModalManager implements ModalManagerClass {
  modals: any[];
  containers: any[];

  constructor() {
    this.modals = []; // Array to store active modals
    this.containers = []; // Array to store containers and their associated modals
  }

  /**
   * Adds a modal to the manager and handles aria-hidden for siblings.
   * @param modal - The modal to add.
   * @param container - The container element for the modal.
   * @returns The index of the modal in the modals array.
   */
  add(modal: any, container: HTMLElement): number {
    let modalIndex = this.modals.indexOf(modal);

    if (modalIndex !== -1) {
      return modalIndex;
    }

    modalIndex = this.modals.length;
    this.modals.push(modal);

    // If the modal is already in the DOM, make it visible to screen readers
    if (modal.modalRef) {
      ariaHidden(modal.modalRef, false);
    }

    const hiddenSiblingNodes = getHiddenSiblings(container);
    ariaHiddenSiblings(
      container,
      modal.mountNode,
      modal.modalRef,
      hiddenSiblingNodes,
      true
    );

    let containerIndex = findIndexOf(
      this.containers,
      item => item.container === container
    );

    if (containerIndex !== -1) {
      this.containers[containerIndex].modals.push(modal);
      return modalIndex;
    }

    this.containers.push({
      modals: [modal],
      container: container,
      restore: null,
      hiddenSiblingNodes: hiddenSiblingNodes
    });

    return modalIndex;
  }

  /**
   * Mounts the modal and handles scroll locking.
   * @param modal - The modal to mount.
   * @param props - The properties for handling the container.
   */
  mount(modal: any, props: any) {
    const containerIndex = findIndexOf(
      this.containers,
      item => item.modals.indexOf(modal) !== -1
    );
    const containerInfo = this.containers[containerIndex];

    if (!containerInfo.restore) {
      containerInfo.restore = handleContainer(containerInfo, props);
    }
  }

  /**
   * Removes a modal from the manager and restores aria-hidden for siblings.
   * @param modal - The modal to remove.
   * @returns The index of the removed modal.
   */
  remove(modal: any): number {
    const modalIndex = this.modals.indexOf(modal);

    if (modalIndex === -1) {
      return modalIndex;
    }

    const containerIndex = findIndexOf(
      this.containers,
      item => item.modals.indexOf(modal) !== -1
    );
    const containerInfo = this.containers[containerIndex];
    containerInfo.modals.splice(containerInfo.modals.indexOf(modal), 1);
    this.modals.splice(modalIndex, 1);

    // If that was the last modal in the container, clean up the container
    if (containerInfo.modals.length === 0) {
      if (containerInfo.restore) {
        containerInfo.restore();
      }

      if (modal.modalRef) {
        ariaHidden(modal.modalRef, true);
      }

      ariaHiddenSiblings(
        containerInfo.container,
        modal.mountNode,
        modal.modalRef,
        containerInfo.hiddenSiblingNodes,
        false
      );
      this.containers.splice(containerIndex, 1);
    } else {
      // Ensure the next top modal is visible to screen readers
      const nextTop = containerInfo.modals[containerInfo.modals.length - 1];
      if (nextTop.modalRef) {
        ariaHidden(nextTop.modalRef, false);
      }
    }

    return modalIndex;
  }

  /**
   * Checks if the given modal is the topmost modal.
   * @param modal - The modal to check.
   * @returns True if the modal is the topmost modal, false otherwise.
   */
  isTopModal(modal: any): boolean {
    return (
      this.modals.length > 0 && this.modals[this.modals.length - 1] === modal
    );
  }
}

export default ModalManager;
