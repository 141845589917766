import { AddressCardType } from "@RHCommerceDev/page-checkout-v2/types";
export const isCanadianPostalCode = (postalCode: string): boolean => {
  const canPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  return canPostalCodeRegex.test(toUpper(postalCode));
};

export const isUSPostalCode = (postalCode: string): boolean => {
  const usPostalCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  return usPostalCodeRegex.test(postalCode);
};

export const isValidPostalCode = (postalCode: string): boolean => {
  return isUSPostalCode(postalCode) || isCanadianPostalCode(postalCode);
};

export const defaultCountryCodeFromPostalCode = (
  postalCode: string,
  defaultCountryCode?: string
) => {
  let country = defaultCountryCode || "US";
  if (postalCode && isValidPostalCode(postalCode)) {
    if (isCanadianPostalCode(postalCode)) {
      country = "CA";
    }
  }
  return country;
};

export const validateAddressByCountry = (
  address: AddressCardType,
  country: string
) => {
  return address?.country === country;
};

/**
 * Canadian postal code should be uppercase 3+3 with a space char between each 3 chars
 * @param {*} postalCode US or Canadian Postal Code
 */
export const cleansePostalCode = (postalCode: string): string => {
  let code = toUpper(postalCode);
  if (isCanadianPostalCode(code) && code.length < 7 && code.charAt(3) !== " ") {
    code = code.substring(0, 3) + " " + code.substring(3);
  }
  return code;
};

export const toUpper = (s?: string): string => {
  if (s) {
    return (s + "").toUpperCase().trim();
  }
  return s;
};

export const normalizeCountry = (
  country: string,
  postalCode: string
): string => {
  if (country) {
    switch (country.toUpperCase().trim()) {
      case "US":
      case "USA":
      case "UNITED STATES":
        return "US";
      case "CA":
      case "CAN":
      case "CANADA":
        return "CA";
      default:
        return defaultCountryCodeFromPostalCode(postalCode);
    }
  }
  return defaultCountryCodeFromPostalCode(postalCode);
};

export const formatAddressToSingleLine = ({
  addressLine1,
  addressLine2,
  city,
  state,
  postalCode
}: Pick<
  AddressType,
  "addressLine1" | "addressLine2" | "city" | "state" | "postalCode"
>): string =>
  `${addressLine1}, ${addressLine2 ? `${addressLine2},` : ""} ${city}, ${
    state ? `${state} ` : ""
  }${postalCode}`;

export const convertAddressInputToAddressValidationInput = (
  {
    addressLine1,
    city,
    state,
    country,
    postalCode,
    phone
  }: Pick<
    AddressInput,
    "addressLine1" | "city" | "state" | "postalCode" | "phone"
  > & {
    country?: Maybe<string>;
  },
  addressType: string
): AddressValidationInput => ({
  addressType,
  addressLine1: addressLine1.replace(/'/g, ""), // apostrophe breaks Melissa
  city,
  state,
  countryCode: country, // Melisa expects countryCode not country
  postalCode: postalCode.trim(), // spaces in postal breaks Melissa
  phone
});

export const convertAddressInputToUserAddAddressInput = ({
  firstName,
  lastName,
  addressLine1,
  addressLine2,
  city,
  state,
  country,
  postalCode,
  phone,
  email: addressInputEmail
}: AddressType): UserAddAddressInput => ({
  firstName: firstName,
  lastName: lastName,
  streetName: addressLine1,
  apartment: addressLine2,
  postalCode: postalCode,
  city: city,
  state: state,
  country: country,
  phone: phone
});

export const convertAddressInputToBillToAddressInput = (
  {
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    postalCode,
    phone
  }: AddressInput | AddressType = {} as AddressType
): BillToAddress => ({
  firstName,
  lastName,
  line1: addressLine1,
  line2: addressLine2,
  city,
  state,
  country,
  postalCode,
  phoneNumber: phone
});

export const convertBillToAddressInputToAddressInput = (
  {
    firstName,
    lastName,
    line1,
    line2,
    city,
    state,
    country,
    postalCode,
    phoneNumber
  }: BillToAddress = {} as BillToAddress
): AddressType => ({
  firstName,
  lastName,
  addressLine1: line1,
  addressLine2: line2,
  city,
  state,
  country,
  postalCode,
  phone: phoneNumber
});

export const convertUserAddressObjectToAddressType = (
  userAddress: UserAddressObject
): AddressType & { id?: string } => ({
  id: userAddress?.id,
  firstName: userAddress?.firstName,
  lastName: userAddress?.lastName,
  addressLine1: userAddress?.streetName,
  addressLine2: userAddress?.additionalStreetInfo || userAddress?.apartment,
  city: userAddress?.city,
  state: userAddress?.state,
  country: userAddress?.country,
  postalCode: userAddress?.postalCode,
  phone: userAddress?.phone,
  companyName: userAddress?.company
});

export const addressHasMostOfTheRequiredFields: (
  address?:
    | Pick<
        AddressType,
        "firstName" | "lastName" | "addressLine1" | "city" | "state"
      >
    | undefined
) => boolean = (address): boolean => {
  const { firstName, lastName, addressLine1, city, state } = address || {};
  return !!firstName && !!lastName && !!addressLine1 && !!city && !!state;
};

export const billAddressHasTheRequiredFields: (
  address?:
    | Pick<
        AddressType,
        "firstName" | "lastName" | "addressLine1" | "city" | "state" | "phone"
      >
    | undefined
) => boolean = (address): boolean => {
  const { firstName, lastName, addressLine1, city, state, phone } =
    address || {};
  return (
    !!firstName && !!lastName && !!addressLine1 && !!city && !!state && !!phone
  );
};

/**
 * # IS THIS ADDRESS SIMILAR?
 *
 * Check if an address is somewhat similar
 * ## [ Vague Logic Collection ]
 *
 * @param addressA partial AddressType
 * @param addressB partial AddressType
 * @returns boolean my guy
 */
export const addressesAreSomewhatSimilar = (
  addressA: Partial<AddressType>,
  addressB: Partial<AddressType>
): boolean =>
  ["lastName", "addressLine1", "city", "state", "postalCode"].reduce(
    (isTheSame: boolean, fieldName: string) => {
      // once its switched to false it should never switch back to true
      if (isTheSame !== false) {
        isTheSame = (addressA || {})[fieldName] === (addressB || {})[fieldName];
      }
      return isTheSame;
    },
    true
  );

/** Stolen from dialog-international-postal-code  */
export const trimPostalCode = (postalCode: string): string =>
  postalCode.trim().length === 6
    ? postalCode.trim().replace(/^(.{3})(.*)$/, "$1 $2")
    : postalCode.trim();

/* format address for AVS address verification*/
export const formatAVSAddress = address => {
  return {
    addressLine1: address?.AddressLine1,
    addressLine2: address?.AddressLine2,
    city: address?.City,
    country: address?.Country,
    email: address?.Email,
    eveningPhone: address?.EveningPhone || "",
    firstName: address?.FirstName,
    lastName: address?.LastName,
    phone: address?.Phone,
    postalCode: address?.PostalCode,
    state: address?.StateProv,
    avsStatus: address?.avsStatus,
    addressSelectedType: address?.addressSelectedType
  } as AddressType;
};
