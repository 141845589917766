import React, {
  cloneElement,
  Component,
  forwardRef,
  ForwardRefRenderFunction,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import ModalManager, { ariaHidden } from "./ModalManager";
import SimpleBackdrop from "./SimpleBackdrop";
import useForkRef from "../utils/useForkRef";
import ownerDocument from "../utils/ownerDocument";
import useEventCallback from "../utils/useEventCallback";
import createChainedFunction from "../utils/createChainedFunction";
import { Portal } from "../Portal";
import { Unstable_TrapFocus } from "../Unstable_TrapFocus";
import { ModalClassKey, ModalProps } from "./types";
import { tmc } from "../utils/tmc";
import { ClassNameMap } from "../types/StandardProps";
import overrideStyles from "../utils/overrideStyles";

function getContainer(
  containerRef: React.RefObject<HTMLElement>,
  container?: HTMLElement | (() => HTMLElement) | Component
): HTMLElement | null {
  container = typeof container === "function" ? container() : container;
  return containerRef.current || (container as HTMLElement | null);
}

function getHasTransition(props: any): boolean {
  return props.children ? props.children.props.hasOwnProperty("in") : false;
}

// A modal manager used to track and manage the state of open Modals.
// Modals don't open on the server so this won't conflict with concurrent requests.
const defaultManager = new ModalManager();

const componentStyles = {
  /* Styles applied to the root element. */
  root: "fixed z-[1300] right-0 left-0 top-0 bottom-0",

  /* Styles applied to the root element if the `Modal` has exited. */
  hidden: "hidden"
};

const ModalRenderFunction: ForwardRefRenderFunction<
  HTMLDivElement,
  ModalProps
> = (props, ref) => {
  const {
    BackdropComponent = SimpleBackdrop,
    BackdropProps,
    children,
    closeAfterTransition = false,
    container,
    disableAutoFocus = false,
    disableBackdropClick = false,
    disableEnforceFocus = false,
    disableEscapeKeyDown = false,
    disablePortal = false,
    disableRestoreFocus = false,
    disableScrollLock = false,
    hideBackdrop = false,
    keepMounted = false,
    manager = defaultManager,
    onBackdropClick,
    onClose,
    onEscapeKeyDown,
    onRendered,
    open,
    ...other
  } = props;

  /* overrideStyles iterates through each key in componentStyles and overrides its styles with the corresponding styles from classes for matching keys. */
  const overriddenStyles: ClassNameMap<ModalClassKey> = overrideStyles(
    "Modal",
    componentStyles,
    props.classes
  );

  const [exited, setExited] = useState(true);
  const modal = useRef<any>({});
  const mountNodeRef = useRef<HTMLElement | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const handleRef = useForkRef(modalRef, ref);
  const hasTransition = getHasTransition(props);

  const getDoc = () => ownerDocument(mountNodeRef.current as any);

  const getModal = () => {
    modal.current.modalRef = modalRef.current;
    modal.current.mountNode = mountNodeRef.current;
    return modal.current;
  };

  const handleMounted = () => {
    manager.mount(getModal(), {
      disableScrollLock
    });
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  };

  const handleOpen = useEventCallback(() => {
    const resolvedContainer =
      getContainer(mountNodeRef, container as any) || getDoc().body;
    manager.add(getModal(), resolvedContainer);

    if (modalRef.current) {
      handleMounted();
    }
  });

  const isTopModal = useCallback(
    () => manager.isTopModal(getModal()),
    [manager]
  );

  const handlePortalRef = useEventCallback((node: HTMLElement | null) => {
    mountNodeRef.current = node;

    if (!node) {
      return;
    }

    if (onRendered) {
      onRendered();
    }

    if (open && isTopModal()) {
      handleMounted();
    } else {
      ariaHidden(modalRef.current as any, true);
    }
  });

  const handleClose = useCallback(() => {
    manager.remove(getModal());
  }, [manager]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  useEffect(() => {
    if (open) {
      handleOpen();
    } else if (!hasTransition || !closeAfterTransition) {
      handleClose();
    }
  }, [open, handleClose, hasTransition, closeAfterTransition, handleOpen]);

  if (!keepMounted && !open && (!hasTransition || exited)) {
    return null;
  }

  const handleEnter = () => {
    setExited(false);
  };

  const handleExited = () => {
    setExited(true);

    if (closeAfterTransition) {
      handleClose();
    }
  };

  const handleBackdropClick = (
    event: MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target !== event.currentTarget) {
      return;
    }

    if (onBackdropClick) {
      onBackdropClick(event as any);
    }

    if (!disableBackdropClick && onClose) {
      onClose(event, "backdropClick");
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== "Escape" || !isTopModal()) {
      return;
    }

    if (onEscapeKeyDown) {
      onEscapeKeyDown(event);
    }

    if (!disableEscapeKeyDown) {
      event.stopPropagation();

      if (onClose) {
        onClose(event, "escapeKeyDown");
      }
    }
  };

  const childProps: any = {};

  if (children.props.tabIndex === undefined) {
    childProps.tabIndex = children.props.tabIndex || "-1";
  }

  if (hasTransition) {
    childProps.onEnter = createChainedFunction(
      handleEnter,
      children.props.onEnter
    );
    childProps.onExited = createChainedFunction(
      handleExited,
      children.props.onExited
    );
  }

  return (
    <Portal
      ref={handlePortalRef}
      container={container}
      disablePortal={disablePortal}
    >
      <div
        ref={handleRef as any}
        onKeyDown={handleKeyDown}
        role="presentation"
        {...other}
        style={{
          ...other.style
        }}
        className={tmc(overriddenStyles.root, {
          [overriddenStyles.hidden]: !open && exited
        })}
      >
        {!hideBackdrop && (
          <BackdropComponent
            open={open}
            onClick={handleBackdropClick}
            {...BackdropProps}
          />
        )}
        <Unstable_TrapFocus
          disableEnforceFocus={disableEnforceFocus}
          disableAutoFocus={disableAutoFocus}
          disableRestoreFocus={disableRestoreFocus}
          getDoc={getDoc}
          isEnabled={isTopModal}
          open={open}
        >
          {cloneElement(children, childProps)}
        </Unstable_TrapFocus>
      </div>
    </Portal>
  );
};

export const Modal = forwardRef(ModalRenderFunction);
