import React, {
  forwardRef,
  ForwardRefRenderFunction,
  isValidElement,
  KeyboardEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef
} from "react";
import { MenuListProps, TextCriteria } from "./types";
import { getScrollbarSize } from "../utils/getScrollbarSize";
import ownerDocument from "../utils/ownerDocument";
import useForkRef from "../utils/useForkRef";
import { List } from "../List";

function nextItem(
  list: HTMLElement,
  item: HTMLElement | null,
  disableListWrap: boolean
) {
  if (list === item) {
    return list.firstChild as HTMLElement;
  }

  if (item && item.nextElementSibling) {
    return item.nextElementSibling as HTMLElement;
  }

  return disableListWrap ? null : (list.firstChild as HTMLElement);
}

function previousItem(
  list: HTMLElement,
  item: HTMLElement | null,
  disableListWrap: boolean
) {
  if (list === item) {
    return disableListWrap
      ? (list.firstChild as HTMLElement)
      : (list.lastChild as HTMLElement);
  }

  if (item && item.previousElementSibling) {
    return item.previousElementSibling as HTMLElement;
  }

  return disableListWrap ? null : (list.lastChild as HTMLElement);
}

function textCriteriaMatches(
  nextFocus: HTMLElement,
  textCriteria: TextCriteria | undefined
) {
  if (textCriteria === undefined) {
    return true;
  }

  let text = nextFocus.innerText;

  if (text === undefined) {
    // jsdom doesn't support innerText
    text = nextFocus.textContent || "";
  }

  text = text.trim().toLowerCase();

  if (text.length === 0) {
    return false;
  }

  if (textCriteria.repeating) {
    return text[0] === textCriteria.keys[0];
  }

  return text.indexOf(textCriteria.keys.join("")) === 0;
}

function moveFocus(
  list: HTMLElement,
  currentFocus: Element | null,
  disableListWrap: boolean,
  disabledItemsFocusable: boolean,
  traversalFunction: (
    list: HTMLElement,
    item: HTMLElement | null,
    disableListWrap: boolean
  ) => HTMLElement | null,
  textCriteria?: TextCriteria
) {
  let wrappedOnce = false;
  let nextFocus = traversalFunction(
    list,
    currentFocus as HTMLElement,
    currentFocus ? disableListWrap : false
  );

  while (nextFocus) {
    // Prevent infinite loop.
    if (nextFocus === list.firstChild) {
      if (wrappedOnce) {
        return;
      }

      wrappedOnce = true;
    }

    const nextFocusDisabled = disabledItemsFocusable
      ? false
      : (nextFocus as any).disabled ||
        nextFocus.getAttribute("aria-disabled") === "true";

    if (
      !nextFocus.hasAttribute("tabindex") ||
      !textCriteriaMatches(nextFocus, textCriteria) ||
      nextFocusDisabled
    ) {
      // Move to the next element.
      nextFocus = traversalFunction(list, nextFocus, disableListWrap);
    } else {
      nextFocus.focus();
      return;
    }
  }
}

const useEnhancedEffect =
  typeof window === "undefined" ? useEffect : useLayoutEffect;

const MenuListRenderFunction: ForwardRefRenderFunction<
  HTMLUListElement,
  MenuListProps
> = (props, ref) => {
  const {
    actions,
    autoFocus = false,
    autoFocusItem = false,
    children,
    className,
    disabledItemsFocusable = false,
    disableListWrap = false,
    onKeyDown,
    variant = "selectedMenu",
    ...other
  } = props;

  const listRef = useRef<HTMLUListElement>(null);
  const textCriteriaRef = useRef<TextCriteria>({
    keys: [],
    repeating: true,
    previousKeyMatched: true,
    lastTime: null
  });

  useEnhancedEffect(() => {
    if (autoFocus) {
      listRef.current?.focus();
    }
  }, [autoFocus]);

  useImperativeHandle(
    actions,
    () => ({
      adjustStyleForScrollbar: (
        containerElement: HTMLElement,
        theme: { direction: string }
      ) => {
        const noExplicitWidth = !listRef.current?.style.width;

        if (
          containerElement.clientHeight < listRef.current!.clientHeight &&
          noExplicitWidth
        ) {
          const scrollbarSize = `${getScrollbarSize()}px`;
          listRef.current!.style[
            theme.direction === "rtl" ? "paddingLeft" : "paddingRight"
          ] = scrollbarSize;
          listRef.current!.style.width = `calc(100% + ${scrollbarSize})`;
        }

        return listRef.current;
      }
    }),
    []
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLUListElement>) => {
    const list = listRef.current!;
    const key = event.key;
    const currentFocus = ownerDocument(list).activeElement;

    if (key === "ArrowDown") {
      event.preventDefault();
      moveFocus(
        list,
        currentFocus,
        disableListWrap,
        disabledItemsFocusable,
        nextItem
      );
    } else if (key === "ArrowUp") {
      event.preventDefault();
      moveFocus(
        list,
        currentFocus,
        disableListWrap,
        disabledItemsFocusable,
        previousItem
      );
    } else if (key === "Home") {
      event.preventDefault();
      moveFocus(list, null, disableListWrap, disabledItemsFocusable, nextItem);
    } else if (key === "End") {
      event.preventDefault();
      moveFocus(
        list,
        null,
        disableListWrap,
        disabledItemsFocusable,
        previousItem
      );
    } else if (key.length === 1) {
      const criteria = textCriteriaRef.current;
      const lowerKey = key.toLowerCase();
      const currTime = performance.now();

      if (criteria.keys.length > 0) {
        if (currTime - criteria.lastTime! > 500) {
          criteria.keys = [];
          criteria.repeating = true;
          criteria.previousKeyMatched = true;
        } else if (criteria.repeating && lowerKey !== criteria.keys[0]) {
          criteria.repeating = false;
        }
      }

      criteria.lastTime = currTime;
      criteria.keys.push(lowerKey);
      const keepFocusOnCurrent =
        currentFocus &&
        !criteria.repeating &&
        textCriteriaMatches(currentFocus as HTMLElement, criteria);

      if (
        criteria.previousKeyMatched &&
        (keepFocusOnCurrent ||
          moveFocus(
            list,
            currentFocus,
            false,
            disabledItemsFocusable,
            nextItem,
            criteria
          ))
      ) {
        event.preventDefault();
      } else {
        criteria.previousKeyMatched = false;
      }
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const handleOwnRef = useCallback((instance: HTMLUListElement | null) => {
    (listRef.current as any) = instance;
  }, []);
  const handleRef = useForkRef(handleOwnRef, ref);

  let activeItemIndex = -1;

  React.Children.forEach(children, (child, index) => {
    if (!isValidElement(child)) {
      return;
    }

    // if (process.env.NODE_ENV !== "production") {
    //   if (isFragment(child)) {
    //     console.error(
    //       "Material-UI: The MenuList = forwardRef(MenuListRenderFunction); component doesn't accept a Fragment as a child. Consider providing an array instead."
    //     );
    //   }
    // }

    if (!child.props.disabled) {
      if (variant === "selectedMenu" && child.props.selected) {
        activeItemIndex = index;
      } else if (activeItemIndex === -1) {
        activeItemIndex = index;
      }
    }
  });

  const items = React.Children.map(children, (child, index) => {
    if (index === activeItemIndex) {
      const newChildProps: any = {};

      if (autoFocusItem) {
        newChildProps.autoFocus = true;
      }

      if (
        (child as any).props.tabIndex === undefined &&
        variant === "selectedMenu"
      ) {
        newChildProps.tabIndex = 0;
      }

      return React.cloneElement(child as any, newChildProps);
    }

    return child;
  });

  return (
    <List
      role="menu"
      ref={handleRef}
      className={className}
      onKeyDown={handleKeyDown}
      tabIndex={autoFocus ? 0 : -1}
      {...other}
    >
      {items}
    </List>
  );
};

export const MenuList = forwardRef(MenuListRenderFunction);
