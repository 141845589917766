import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import yn from "yn";
import { NetworkStatus, useQuery } from "@apollo/client";

import { usePageContentNoProxy } from "custom-providers/LocationProvider";

import { querySearch } from "@RHCommerceDev/graphql-client-nextgen-search";

import analyticsLoader from "@RHCommerceDev/analytics/loader";

import { useAppId } from "@RHCommerceDev/hooks-use-app-id";
import useBrand from "@RHCommerceDev/hooks-use-brand/useBrand";
import useIsoRedirect from "@RHCommerceDev/hooks-use-isoredirect";
import useLocale from "@RHCommerceDev/hooks-use-locale/useLocale";
import { useRhUserAtomValue } from "hooks/atoms";
import useAppData from "@RHCommerceDev/hooks/useAppData";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { useCurrencyCode } from "@RHCommerceDev/hooks/useCurrencyCode";
import useDidMountEffect from "@RHCommerceDev/hooks/useDidMountEffect";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useFetchParams } from "@RHCommerceDev/hooks/useFetchParams/useFetchParams";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import { useParams2 } from "@RHCommerceDev/hooks/useParams";
import { processEnvServer as isServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useUserContext from "@RHCommerceDev/hooks/useUserContext";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";

import { getMemberSavingsText } from "@RHCommerceDev/utils/memberSavingsTextUtils";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";
import {
  isGTMLoaded,
  isOnProductPage,
  isOnSearchPage
} from "@RHCommerceDev/utils/analytics/utils";
import buildPath from "@RHCommerceDev/utils/buildPath";
import {
  isSSRToggledCheck,
  isSSRToggledWithClientRender
} from "@RHCommerceDev/utils/ssrHelpers";
import {
  BRAND_NAME_TO_BRAND_CODE,
  BREAKPOINT_SM,
  DEFAULT_SELECTED_BRAND,
  ITEMS_PER_PAGE_PREFERENCE,
  QUERY_PARAM_SITE,
  SKIP_CALL_USERTYPE,
  ZERO_RESULTS,
  BCT_PATHS
} from "@RHCommerceDev/utils/constants";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";

import { SearchQueryVariables } from "./types";
import { extractOnSalePartFromArray } from "./helper";
import {
  DEFAULT_GRID_COL,
  DEFAULT_GRID_COL_CONCIERGE,
  LAST_SORTMENU,
  PG_GRID_CHOICE,
  SELECTED_BRAND_COOKIE,
  DESIRED_CONCEPT_TAB_ORDER,
  FEATURE_CONCEPT_TABS,
  FEATURE_SEARCH_ALL_BRANDS,
  SITES
} from "./constants";
import { Theme, useMediaQuery } from "@material-ui/core";
import { removeNttSearchParams } from "@RHCommerceDev/utils/formatSearchUrl";
import { pageNoUrlPattern } from "@RHCommerceDev/utils/regex";
import { useSetSipId } from "@RHCommerceDev/hooks/atoms/useSIPID";
import useSite from "hooks/useSite";

export const useNextgenProductGalleryData: any = props => {
  const { onError, categoryId } = props;

  const env = useEnv();
  const { search: locationSearch } = useLocation();
  const { pathname } = useLocation();
  const isAemPage = !pathname?.includes(".jsp");
  const { pageContent } = usePageContentNoProxy();
  const { isConcierge } = useAppId();
  const cookie = useIsoCookies([
    PG_GRID_CHOICE,
    LAST_SORTMENU,
    FEATURE_CONCEPT_TABS,
    FEATURE_SEARCH_ALL_BRANDS
  ]);
  const nextgenCookie = "true";

  const [gridColumns, setGridColumns] = useState<number>(() => {
    const defaultGridView =
      cookie[PG_GRID_CHOICE] && parseInt(cookie[PG_GRID_CHOICE]);
    return isConcierge
      ? DEFAULT_GRID_COL_CONCIERGE
      : defaultGridView
      ? defaultGridView
      : DEFAULT_GRID_COL;
  });
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const searchParams = new URLSearchParams(locationSearch);
  const loadAllParam = searchParams.get("loadAll") === "true";
  // lazy load is enabled when the url has loadAll param, which lazyloads items after every 100 items
  const isLazyLoadEnabled = useMemo(() => {
    return loadAllParam;
  }, [loadAllParam]);

  const selectedRefinementContainer = React.useRef<HTMLDivElement | null>(null);

  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

  const [isSaleFilterEnabled, setIsSaleFilterEnabled] =
    useState<boolean>(false);

  const [sortingLabel, setSortingLabel] = useState(null);

  const { app } = useAppData();
  const userContext = useUserContext([app.postalCode]);
  const brand = useBrand();
  const rhUser = useRhUserAtomValue();
  const history = useHistory();
  const prefix = useLocalization();
  const { searchText } = useParams<{ searchText: string }>();
  const searchPath = yn(env?.FEATURE_URL_CHANGE) ? searchText : "results.jsp";
  const pc = useIsoCookies(["pc"])?.pc;

  const { setCookieWrapper, setStorageValueWrapper } =
    useCookiesWithPermission();

  const smDown = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down(BREAKPOINT_SM)
  );

  const isSSRToggledWithCSR = isSSRToggledWithClientRender();
  const isSSRToggled = isSSRToggledCheck();
  const isLoadedGTM = isGTMLoaded();
  const isProductPage = isOnProductPage();
  const isSearchPage = isOnSearchPage();
  const {
    previousState: { country }
  } = useUserPreferences();

  const currencyCode = useCurrencyCode({
    postalCode: app.postalCode || pc,
    userType: rhUser?.userType,
    country
  });

  const [userType, setUserType] = useState(rhUser?.userType);

  const storedItemsPerPagePreference = !isServer
    ? localStorage.getItem(ITEMS_PER_PAGE_PREFERENCE)
    : undefined;

  const { params } = useParams2<SearchQueryVariables>(
    {
      ntt: "",
      n: undefined,
      contentType: undefined,
      nrpp: "12",
      ns: undefined,
      no: undefined,
      country,
      currencyCode,
      userType: rhUser?.userType || undefined,
      view: "",
      show_finish: "0",
      categoryId: "",
      sale: "",
      site: "",
      pgterm: "",
      fromCG: "",
      locale: "",
      maxnrpp: storedItemsPerPagePreference ?? "48",
      version: "",
      sortBy: "",
      filters: [],
      searchterm: "",
      activeTab: ""
    },
    {
      toLowerCase: true
    }
  );

  const req = getReqContext();
  let _params = useFetchParams(
    isServer ? req?.path : location.pathname,
    yn(env.FEATURE_URL_CHANGE) ? "pg" : "products.jsp"
  );

  let [_categoryId, _pgterm] = _params;

  if (params.categoryId === "") {
    params.categoryId = _categoryId;
    if (_pgterm) {
      params.pgterm = _pgterm?.replace(/-/g, " ")?.replace("&ct=true", "");
    }
  }

  const isPGPage = Boolean(
    pathname === `${prefix}/catalog/category/products.jsp` ||
      pathname === `${prefix}/outdoor/catalog/category/products.jsp` ||
      pathname ===
        `${prefix}/catalog/category/products.jsp/${_categoryId}/${_pgterm?.replace(
          /[ +]/g,
          "-"
        )}` ||
      pathname ===
        `${prefix}/outdoor/catalog/category/products.jsp/${_categoryId}/${_pgterm?.replace(
          /[ +]/g,
          "-"
        )}` ||
      pathname ===
        `${prefix}/outdoor/catalog/category/products.jsp/${_categoryId}` ||
      pathname === `${prefix}/catalog/category/products.jsp/${_categoryId}`
  );

  const isAemEnabled =
    pageContent?.pgHeader?.templateName || pageContent?.pgFooter?.templateName;

  useEffect(() => {
    let defaultGridView =
      (cookie[PG_GRID_CHOICE] && parseInt(cookie[PG_GRID_CHOICE])) ||
      (smDown
        ? 12
        : (smUp && env.ABTEST_PG_2UP && params.fromCG === "true" && 6) ||
          (mdUp ? 4 : 6));
    if (mdUp && defaultGridView === 12) {
      defaultGridView = 4;
    }
    if (defaultGridView && [4, 6, 12].includes(defaultGridView)) {
      setGridColumns(defaultGridView === 4 && !smUp ? 6 : defaultGridView);
      setCookieWrapper(PG_GRID_CHOICE, defaultGridView, {
        maxAge: 2592000,
        domain: `.${env.ENV_DOMAIN}`,
        path: "/"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie[PG_GRID_CHOICE], mdUp, smUp, params.fromCG, smDown]);

  const setSipId = useSetSipId();

  const saveGridCol = useCallback(
    col => {
      setCookieWrapper(PG_GRID_CHOICE, col, {
        maxAge: 2592000,
        domain: `.${env.ENV_DOMAIN}`,
        path: "/"
      });

      setGridColumns(col);

      setSipId(null);
    },
    [env.ENV_DOMAIN, setCookieWrapper]
  );

  // The sales page will be considered when the sale filter is not applied to persist the saleContext header.
  const isSale = params.sale === "true";
  const isStockedFilterActive = params?.filters.includes("instock_i:1");
  const isRefinementFilterActive = params?.filters?.length > 0;
  const isNewArrivalsFilterActive =
    params?.filters?.includes("new_product_i:1");
  const pathArray = (!isServer ? location.pathname : req?.path)?.split("/");

  const siteValueFromUrl = useMemo(() => {
    if (pathArray?.includes("babyandchild")) return "BC";
    else if (pathArray?.includes("teen")) return "TN";
    return "RH";
  }, [pathArray]);
  const site = yn(env.FEATURE_BCT_SUNSET) ? siteValueFromUrl : useSite();

  const locale = useLocale();
  const isSort = !!params.sortBy && params.ns !== "product.sale|1";

  const contactUsPath = "/customer-experience/contact-us";

  const isNextGen = params.version === "nextgen" || nextgenCookie === "true";

  if (isNextGen) {
    const filters = searchParams?.getAll("filter");
    const decodeParams = filters?.map(item => decodeURIComponent(item));
    params.filters = decodeParams;
  }

  if (isSale && !isStockedFilterActive) {
    params.filters = [...params.filters, "on_sale_i:1"]?.filter(
      param => param !== "sale"
    );
  }

  useDidMountEffect(() => {
    if (!SKIP_CALL_USERTYPE?.includes(rhUser?.userType?.toLowerCase())) {
      setUserType(rhUser?.userType);
    }
  }, [rhUser?.userType]);

  const filterdParams = Object.fromEntries(
    Object.entries(params).filter(([_key, value]) => value && value?.length)
  );

  const { activeTab, ...restParams } = filterdParams;

  const {
    data,
    error,
    refetch,
    fetchMore,
    networkStatus,
    loading: searchQueryLoading
  } = useQuery<Pick<Query, "search">, QuerySearchArgs>(querySearch, {
    variables: {
      ...restParams,
      ntt: env?.FEATURE_URL_CHANGE ? searchText : restParams?.ntt,
      categoryId: params.categoryId || categoryId,
      sale: isSale,
      nrpp: params?.maxnrpp
        ? Number(params?.maxnrpp)
        : params?.nrpp
        ? Number(params?.nrpp)
        : isSSRToggledWithCSR
        ? 48
        : storedItemsPerPagePreference
        ? +storedItemsPerPagePreference
        : 48,
      no: params.no ? Number(params.no) : undefined,
      currencyCode,
      country,
      isSearchBoxUse: !isPGPage,
      site: isConcierge ? params?.site || site : params?.site || site,
      ns:
        cookie?.[LAST_SORTMENU] ?? (params.ns !== "null" && params.ns)
          ? params.ns
          : undefined,
      ...(isPGPage &&
        params.pgterm && {
          ntt: params.pgterm
        }),
      locale: locale,
      userType: userType,
      nextgenDriven: isNextGen ? true : false,
      ...(isPGPage && params?.searchterm && { searchTerm: params?.searchterm }),
      ...(params?.site &&
        params?.filters?.length === 0 && { activeTab: activeTab })
    },
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    fetchPolicy: isLazyLoadEnabled ? "cache-first" : "cache-and-network",
    // fetchPolicy: "no-cache",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    skip: !userType,
    onCompleted: () => {
      setGridColumns(gridColumns);
      if (!yn(env.REMOVE_ANALYTICS_LOADER)) {
        if (!isServer && isSearchPage) {
          memoryStorage.setItem("ruleExecuted", search?.ruleExecuted);
          const itemList = search?.resultList?.records?.map(
            rec => rec?.sku?.fullSkuId
          );
          analyticsLoader(a =>
            a.emitAnalyticsEvent(
              document.querySelector("#spa-root > *")! as HTMLElement,
              [a.EVENTS.GA4_VIEW_PAGE_SEARCH.INT_TYPE],
              {
                rule: search.ruleExecuted,
                itemList,
                item_list_name: true,
                id: params?.categoryId || categoryId
              }
            )
          );
        }
        if (
          !isServer &&
          isSearchPage &&
          search?.resultList?.firstRecNum === 0
        ) {
          analyticsLoader(a =>
            a.emitAnalyticsEvent(
              document.querySelector("#spa-root > *")! as HTMLElement,
              a.EVENTS.FUSION_SEARCH_SIGNAL.INT_TYPE,
              {
                search: true,
                rule: search?.ruleExecuted
              }
            )
          );
        }
        if (!data?.search?.resultList && isSearchPage) {
          analyticsLoader(a =>
            a.emitAnalyticsEvent(
              document.querySelector("#spa-root > *")! as HTMLElement,
              a.EVENTS.SEARCH.INT_TYPE,
              {
                rule: search?.ruleExecuted,
                search_results_number: 0,
                id: params?.categoryId || categoryId
              }
            )
          );
        }
        const direct = localStorage.getItem("analytics-searchbar-enter");
        if (direct && data?.search?.resultList && isSearchPage) {
          analyticsLoader(a =>
            a.emitAnalyticsEvent(
              document.querySelector("#spa-root > *")! as HTMLElement,
              a.EVENTS.SEARCH.INT_TYPE,
              {
                anchor:
                  data?.search?.resultList?.records?.[0]?.product?.anchor ??
                  params?.ntt,
                search_results_number: search?.resultList?.totalNumRecs || 0,
                rule: search?.ruleExecuted,
                id: params?.categoryId || categoryId
              }
            )
          );
          localStorage.removeItem("analytics-searchbar-enter");
        }
      }
    },
    onError: () => {
      if (!yn(env.REMOVE_ANALYTICS_LOADER) && !isServer && isSearchPage) {
        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.SEARCH.INT_TYPE,
            {
              rule: search?.ruleExecuted,
              search_results_number: 0,
              id: params?.categoryId || categoryId
            }
          )
        );
      }
    }
  });

  const search: SearchResponse = data?.search;

  let productTitle = search?.categoryDisplayName;

  const seoPageTitle = search?.saleMemberInfo?.headerConfig?.seoPageTitle;
  const seoPageDescription = search?.saleMemberInfo?.headerConfig?.seoDesc_s;

  if (pathname === `${prefix}/search/${searchPath}`) {
    productTitle = search?.adjustedSearches?.length
      ? search?.adjustedSearches?.[0]?.adjustedTerms
      : yn(env?.FEATURE_URL_CHANGE)
      ? searchText
      : params?.ntt || params?.searchterm;
  }

  const templateString =
    search?.saleMemberInfo?.headerConfig?.template?.toLowerCase() ===
    "horizontal"
      ? search?.saleMemberInfo?.headerConfig?.template?.toLowerCase()
      : "";
  const productList = search?.resultList?.records || [];
  const sortMenu = search?.resultList?.sortOptions || [];
  const refMenu = search?.refinementMenus || [];
  const sizeFacets = search?.sizeFacets || [];
  const booleanFacets = search?.booleanFacets || [];
  const materialFacets = search?.materialFacets || [];
  const totalNumRecs = search?.resultList?.totalNumRecs || 0;

  const loadMoreData = useCallback(
    (page?: number, no?: number) => {
      if (isNextPageLoading || productList?.length >= totalNumRecs) {
        return;
      }
      setIsNextPageLoading(true);

      fetchMore({
        variables: {
          no: no ?? (+(params.no || 0) + productList?.length || ZERO_RESULTS),
          infiniteScroll: isLazyLoadEnabled,
          nrpp: Number(params.maxnrpp) || 12
        },
        updateQuery: (prev: Pick<Query, "search">, { fetchMoreResult }) => {
          if (networkStatus === NetworkStatus.fetchMore || !fetchMoreResult) {
            return prev;
          }

          return {
            ...prev,
            search: {
              ...prev?.search,
              resultList: {
                ...prev?.search?.resultList,
                records: prev?.search?.resultList?.records
                  ? [
                      ...prev?.search?.resultList?.records,
                      ...fetchMoreResult?.search?.resultList?.records
                    ]
                  : fetchMoreResult?.search?.resultList?.records
              },
              headerConfig: fetchMoreResult?.search?.saleMemberInfo
                ?.headerConfig
                ? { ...fetchMoreResult?.search?.saleMemberInfo?.headerConfig }
                : prev?.search?.headerConfig
            }
          };
        }
      });
    },
    [
      networkStatus,
      isNextPageLoading,
      productList,
      totalNumRecs,
      fetchMore,
      isLazyLoadEnabled
    ]
  );

  const checkVisibility = () => {
    if (
      !isServer &&
      (search?.resultList?.totalNumRecs || 0) > 0 &&
      (isProductPage || isSearchPage) &&
      (search?.saleMemberInfo?.headerConfig?.title || search?.q) &&
      rhUser?.akamaiCountryCode &&
      isLoadedGTM?.isGTMLoaded &&
      !yn(env.REMOVE_ANALYTICS_LOADER)
    ) {
      const products = document?.querySelectorAll(".productVisible");
      const itemListAll = search?.resultList?.records?.map((rec, index) => {
        return {
          item_name: rec?.product?.displayName,
          item_id: rec?.product?.repositoryId,
          item_variant: rec?.sku?.fullSkuId,
          index: index,
          item_list_name: search?.saleMemberInfo?.headerConfig?.title ?? "pdp"
        };
      });

      let arrayOfVisibleItems = [];
      products?.forEach(product => {
        if (product) {
          const rect = product?.getBoundingClientRect();
          const isVisible =
            rect.top >= 0 &&
            rect.bottom <=
              (window?.innerHeight || document?.documentElement.clientHeight);
          // remove contains if add all visible items to dataLayer
          if (isVisible && !product?.classList.contains("visibleViewItem")) {
            let skuOfProduct = product?.id.split("__")[1];
            let indexOfProduct = product?.id.split("__")[2];
            arrayOfVisibleItems.push({
              sku: skuOfProduct,
              skuIndex: skuOfProduct + "__" + indexOfProduct
            });
            product.classList.add("visibleViewItem");
          }
        }
      });

      const targetIds = new Set(
        arrayOfVisibleItems.map(item => item?.skuIndex)
      );
      const itemList = itemListAll?.filter(item => {
        const combinedId = `${item?.item_variant}__${item?.index}`;
        return targetIds?.has(combinedId);
      });
      if (itemList?.length > 0 && (isProductPage || isSearchPage)) {
        const category = search?.q?.match(/\/(cat\d+)(?=\?|$)/);
        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.VIEW_ITEM_LIST.INT_TYPE,
            {
              itemList,
              item_list_id: search?.q?.includes("category:")
                ? search?.q?.split("category:")?.[1]
                : category?.[1] || search?.q,
              item_list_name_view_item:
                search?.saleMemberInfo?.headerConfig?.title ?? "pdp",
              rule: search?.ruleExecuted,
              id: params?.categoryId || categoryId
            }
          )
        );
      }
    }
  };

  useEffect(() => {
    if (
      !isServer &&
      (search?.resultList?.totalNumRecs || 0) > 0 &&
      (isProductPage || isSearchPage) &&
      (search?.saleMemberInfo?.headerConfig?.title || search?.q) &&
      rhUser?.akamaiCountryCode &&
      isLoadedGTM?.isGTMLoaded &&
      !yn(env.REMOVE_ANALYTICS_LOADER)
    ) {
      let scrollTimeout;

      const handleScroll = () => {
        clearTimeout(scrollTimeout);

        scrollTimeout = setTimeout(() => {
          checkVisibility();
        }, 100);
      };
      window?.addEventListener("load", checkVisibility);
      window?.addEventListener("scroll", handleScroll);
      window?.addEventListener("resize", checkVisibility);

      checkVisibility();

      // Cleanup function to remove the event listeners
      return () => {
        window?.removeEventListener("scroll", handleScroll);
        window?.removeEventListener("resize", checkVisibility);
        window?.removeEventListener("load", checkVisibility);
        clearTimeout(scrollTimeout);
      };
    }
  }, [
    search,
    loadAllParam,
    rhUser?.akamaiCountryCode,
    isLoadedGTM?.isGTMLoaded
  ]);

  useEffect(() => {
    const newIsNextPageLoading = false;
    if (isNextPageLoading === newIsNextPageLoading) return;
    setIsNextPageLoading(newIsNextPageLoading);
  }, [productList]);

  const singleRecordRedirectURL = useCallback(() => {
    const productId = search?.resultList?.records[0]?.product?.repositoryId;
    const fullSkuId = search?.resultList?.records[0]?.sku?.fullSkuId;
    const prevNtt = search?.q;
    const site = search?.tabInfo?.tabs[0]?.site;
    const bctPath = BCT_PATHS[site] || "";

    return `${prefix}${
      yn(env.FEATURE_BCT_SUNSET) ? bctPath : ""
    }/catalog/product/product.jsp?productId=${productId}&fullSkuId=${fullSkuId}&categoryId=search${
      prevNtt?.split(" ")?.[1]
        ? `&prevfullSkuId=${prevNtt}`
        : prevNtt?.split("+")?.[1]
        ? `&prevfullSkuId=${prevNtt}`
        : ""
    }`;
  }, [
    prefix,
    search?.resultList?.records,
    search?.resultTabs,
    search?.sku_siteId,
    site
  ]);

  useEffect(() => {
    // if total number of result is 1 redirect to PDP
    if (!searchQueryLoading && search) {
      const { resultList, redirectUrl } = search || {};
      const productId = search?.resultList?.records?.[0]?.product?.repositoryId;
      const totalNumRecs = resultList?.totalNumRecs;
      const targetUrl = "/catalog/product/product.jsp";
      if (search?.skuSearch && !!search?.resultList?.totalNumRecs) {
        const skuSiteIdResult = search?.sku_siteId?.includes(site);
        processEnvServer || skuSiteIdResult
          ? useIsoRedirect(singleRecordRedirectURL(), "windowReload")
          : location?.replace(singleRecordRedirectURL());
        return;
      }

      if (redirectUrl) {
        let localizedRedirectUrl = "";
        if (
          redirectUrl?.startsWith("https") ||
          redirectUrl?.startsWith("http")
        ) {
          localizedRedirectUrl = redirectUrl;
        } else {
          localizedRedirectUrl = prefix + redirectUrl;
        }

        totalNumRecs
          ? history.push(localizedRedirectUrl)
          : history.replace(localizedRedirectUrl);
      }

      if (
        (totalNumRecs === 1 && !redirectUrl && productId) ||
        (!totalNumRecs && !!redirectUrl)
      ) {
        if (!redirectUrl) {
          let queryParams = { productId: productId } as any;
          if (isStockedFilterActive) {
            if (!search?.inStockFlow) {
              queryParams.inStock = "true";
            }
          }
          if (params?.sale == "true") {
            queryParams.sale = "true";
          }
          history.replace(buildPath(`${prefix}${targetUrl}`, queryParams));
        }
      }
    }
  }, [search, searchQueryLoading, history, prefix, singleRecordRedirectURL]);

  useEffect(() => {
    const newIsLoading =
      networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables;
    if (isLoading === newIsLoading) return;
    setIsLoading(newIsLoading);
  }, [networkStatus]);

  if (
    search?.resultTabs?.length &&
    isConcierge &&
    !isServer &&
    search &&
    Object.isExtensible(search)
  ) {
    const host = window.location.protocol + "//" + window.location.host;
    search?.resultTabs?.map((resultTab: SearchResultTab) => {
      if (resultTab?.link?.host || resultTab?.displayName) {
        resultTab.link.host = host;
        const brandCode =
          resultTab?.displayName in BRAND_NAME_TO_BRAND_CODE
            ? BRAND_NAME_TO_BRAND_CODE[resultTab?.displayName]
            : DEFAULT_SELECTED_BRAND;
        if (
          resultTab?.link?.navigationState &&
          !resultTab?.link?.navigationState.includes(QUERY_PARAM_SITE)
        ) {
          resultTab.link.navigationState = `${resultTab?.link?.navigationState}&site=${brandCode}`;
        }
      }
    });
  }

  const navigateToOtherResult = useCallback(() => {
    if (
      !search?.resultList &&
      (search?.zeroResults?.otherBrandSearchResults || []).length > 0
    ) {
      //add brand code with max number of result
      const largestBrandResult: SearchOtherBrandSearchResult =
        search?.zeroResults?.otherBrandSearchResults.reduce((max, brand) => {
          return brand.brandResultCount > max.brandResultCount ? brand : max;
        }, search?.zeroResults?.otherBrandSearchResults?.[0]);
      const brandCode =
        largestBrandResult?.brandName in BRAND_NAME_TO_BRAND_CODE
          ? BRAND_NAME_TO_BRAND_CODE[largestBrandResult?.brandName]
          : DEFAULT_SELECTED_BRAND;

      if (isConcierge && !isServer) {
        const host = window.location.protocol + "//" + window.location.host;
        let navigationState = "";
        search?.zeroResults?.otherBrandSearchResults?.map(
          (otherBrandSearchResult: SearchOtherBrandSearchResult, index) => {
            if (otherBrandSearchResult?.brandLink?.host) {
              // otherBrandSearchResult.brandLink.host = host;

              // --- bug fix NGSEARCH-212 picking the last present branch code causing incorrect site id to passed in navigationState ---//

              // const brandCode =
              //   otherBrandSearchResult?.brandName in BRAND_NAME_TO_BRAND_CODE
              //     ? BRAND_NAME_TO_BRAND_CODE[otherBrandSearchResult?.brandName]
              //     : DEFAULT_SELECTED_BRAND;

              if (index === 0) {
                setCookieWrapper(SELECTED_BRAND_COOKIE, brandCode, {
                  expires: new Date(2147483647000)
                });
              }
              if (
                otherBrandSearchResult?.brandLink?.navigationState &&
                !otherBrandSearchResult?.brandLink?.navigationState.includes(
                  QUERY_PARAM_SITE
                )
              ) {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const Ns = urlParams.get("Ns");
                const Ntt = urlParams.get("Ntt");
                // otherBrandSearchResult.brandLink.navigationState = `?Ns=${encodeURIComponent(
                //   Ns || ""
                // )}&Ntt=${Ntt}&site=${brandCode}`;
                navigationState = `?Ns=${encodeURIComponent(
                  Ns || ""
                )}&Ntt=${Ntt}&site=${brandCode}`;
              } else {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const Ns = urlParams.get("Ns");
                const Ntt = urlParams.get("Ntt");
                // otherBrandSearchResult.brandLink.navigationState = `?Ns=${encodeURIComponent(
                //   Ns || ""
                // )}&Ntt=${Ntt}&site=${brandCode}`;
                navigationState = `?Ns=${encodeURIComponent(
                  Ns || ""
                )}&Ntt=${Ntt}&site=${brandCode}`;
              }
            }
            navigationState = removeNttSearchParams(navigationState);
            return useIsoRedirect(
              `${host}${prefix}/search/${searchPath}${navigationState}`,
              "windowAssign"
            );
          }
        );
      } else {
        const navState = removeNttSearchParams(
          `${
            (search?.zeroResults?.otherBrandSearchResults || [])[0]?.brandLink
              ?.navigationState
          }`
        );
        const { otherBrandSearchResults = [] } = search?.zeroResults || {};
        const brandName = otherBrandSearchResults[0]?.brandName;
        const brandLink = otherBrandSearchResults[0]?.brandLink;
        const site = SITES[brandName];
        const bctPath = BCT_PATHS[site];

        if (yn(env.FEATURE_BCT_SUNSET) && bctPath) {
          return useIsoRedirect(
            `${prefix}${bctPath}/search/${searchPath}${navState}`,
            "windowOpen"
          );
        }
        return useIsoRedirect(
          `${brandLink?.host}${prefix}/search/${searchPath}${navState}`,
          "windowAssign"
        );
      }
    }
  }, [
    isConcierge,
    prefix,
    search?.resultList,
    search?.zeroResults?.otherBrandSearchResults
  ]);

  const [reloadKey, setReloadKey] = useState(null);

  useEffect(() => {
    if (gridColumns) {
      setGridColumns(gridColumns);
    }
  }, [gridColumns]);

  useEffect(() => {
    if (window) {
      window.history.scrollRestoration = "manual";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env]);

  useDidMountEffect(() => {
    if (gridColumns === 4 && !smUp) {
      setGridColumns(6);
      setCookieWrapper(PG_GRID_CHOICE, 6, {
        maxAge: 2592000,
        domain: `.${env.ENV_DOMAIN}`,
        path: "/"
      });
    }
  }, [smUp]);

  useDidMountEffect(() => {
    if (params?.no && location.search) {
      history.push({
        pathname: pathname,
        search: location.search.replace(pageNoUrlPattern, "")
      });
    } else {
      refetch?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.currencyCode, country]);

  const getSortingLabel = sortMenu => {
    sortMenu?.filter(item => {
      if (item?.selected) {
        setSortingLabel(item?.label);
      }
    });
  };

  useEffect(() => {
    getSortingLabel(sortMenu);
  }, [sortMenu]);

  useEffect(() => {
    if (
      !isServer &&
      (search?.resultList?.totalNumRecs || 0) > 0 &&
      isProductPage &&
      search?.saleMemberInfo?.headerConfig?.title &&
      rhUser?.akamaiCountryCode &&
      isLoadedGTM?.isGTMLoaded &&
      !yn(env.REMOVE_ANALYTICS_LOADER)
    ) {
      memoryStorage.setItem("ruleExecuted", search?.ruleExecuted);
      setStorageValueWrapper({
        storageKey: "analytics-category-title",
        value: search?.saleMemberInfo?.headerConfig?.title
      });
      const itemList = search?.resultList?.records?.map(
        rec => rec?.sku?.fullSkuId
      );
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          [a.EVENTS.GA4_VIEW_PAGE_PRODUCT.INT_TYPE],
          {
            displayName: search?.saleMemberInfo?.headerConfig?.title,
            promoDisplay: search?.saleMemberInfo?.headerConfig?.promoDisplay,
            rule: search?.ruleExecuted,
            itemList,
            item_list_name: true,
            id: params?.categoryId || categoryId
          }
        )
      );
    }
  }, [
    search?.saleMemberInfo?.headerConfig?.title,
    search?.saleMemberInfo?.headerConfig?.promoDisplay,
    rhUser?.akamaiCountryCode,
    isLoadedGTM?.isGTMLoaded
  ]);

  // Add "sale=true" to ProductCard URL when SALE filter is active
  // TODO: convert it to a hook
  useEffect(() => {
    if (extractOnSalePartFromArray(params?.filters)?.includes("on_sale_i:1")) {
      setIsSaleFilterEnabled?.(true);
    } else {
      setIsSaleFilterEnabled?.(false);
    }
  }, [params.filters.length]);

  const resetRefinement = useCallback(() => {
    if (isPGPage) {
      let resetUrl = `${
        params.pgterm ? `pgterm=${params.pgterm}&` : ""
      }categoryId=${params.categoryId || categoryId}`;
      if (params?.fromCG === "true") {
        resetUrl += "&fromCG=true";
      }
      history.push({
        search: resetUrl
      });
    } else {
      const serarchValue = new URLSearchParams();
      serarchValue.set("Ntt", params.ntt);
      if (params?.site && yn(env.FEATURE_SEARCH_ALL_BRANDS)) {
        serarchValue.set("site", params?.site);
      }

      if (params?.fromCG === "true") {
        serarchValue.set("fromCG", "true");
      }
      const navState = removeNttSearchParams(serarchValue.toString());
      history.push({
        search: navState
      });
    }
  }, [
    history,
    isPGPage,
    params.categoryId,
    categoryId,
    params.ntt,
    params.pgterm,
    params.site
  ]);

  if (!isLoading && search && !yn(env.REMOVE_ANALYTICS_LOADER)) {
    const fusionData = {
      fusion_id: search.searchQueryId,
      fusion_filter: search.filterQueries
    };
    analyticsLoader(a =>
      a.emitAnalyticsEvent(
        document.querySelector("#spa-root > *")! as HTMLElement,
        a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
        {
          set: {
            name: "fusion_data",
            value: JSON.stringify(fusionData)
          }
        }
      )
    );
  }

  const saveSelectedProduct = useCallback(
    sipId => {
      setSipId(sipId);
    },
    [setSipId]
  );

  const forceSaleFlag =
    !!search?.ATGCollectionGalleryInfo?.collectionGallery?.[0]?.forceSaleFlag;
  const percentSaleSkus = Number(
    search?.saleMemberInfo?.pgSaleStats?.percentSaleSkus
  );
  const memberSavings = useMemo(
    () =>
      Math.round(
        (search?.saleMemberInfo?.pgMemberSavings?.memberSavingsMax ?? 0) / 5
      ) * 5,
    [search?.saleMemberInfo?.pgMemberSavings?.memberSavingsMax]
  );

  const shouldShowSaleText = useMemo(
    () => !isNewArrivalsFilterActive,
    [percentSaleSkus, isSale, isNewArrivalsFilterActive]
  );
  const allItemsOnSale = useMemo(
    () => percentSaleSkus === 100 || forceSaleFlag,
    [percentSaleSkus, forceSaleFlag]
  );

  //Show sale message in PG header for CG -> PG
  const activeSaleFilter =
    params.fromCG === "true"
      ? true
      : (params.n ?? "").includes('sku_showOnly:("Sale")') ||
        extractOnSalePartFromArray(params?.filters)?.includes("on_sale_i:1");

  const dynamicMemberSavingsText = getMemberSavingsText(
    pageContent,
    percentSaleSkus,
    search?.saleMemberInfo?.pgMemberSavings?.memberSavingsMin,
    search?.saleMemberInfo?.pgMemberSavings?.memberSavingsMax
  );

  const selectSaleItemsOnSale = useMemo(
    () =>
      !isNaN(percentSaleSkus)
        ? percentSaleSkus > 0 && percentSaleSkus < 20
        : true,
    [percentSaleSkus]
  );

  const selectItemsOnSale = useMemo(
    () => percentSaleSkus >= 20 && percentSaleSkus < 100,
    [percentSaleSkus]
  );

  // const keywords = React.useMemo(
  //   () => root?.keywords?.join(", "),
  //   [root?.keywords]
  // );
  useEffect(() => {
    if (error && onError) {
      onError();
      return null;
    }
  }, [error, onError]);

  const handleTabClick = useCallback(
    tabData => {
      const searchParams = new URLSearchParams(location.search);
      const nttValue = searchParams.get("Ntt");
      const ntt = nttValue ? nttValue : params.ntt;
      searchParams.set("Ntt", ntt);
      searchParams.set("activeTab", tabData?.tabNo);
      searchParams.set("site", tabData?.site);
      searchParams.set("no", "0");
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    },
    [isServer ? req : location]
  );

  const { tapInfoOptions, maxTabId } = useMemo(() => {
    const tapInfoOptions = (search?.tabInfo?.tabs || []).filter(
      tab => tab?.numResults
    );
    // find max for first search
    const maxTabId = search?.tabInfo?.tabs?.reduce((maxTab, currentTab) =>
      currentTab.numResults > maxTab.numResults ? currentTab : maxTab
    ).tabNo;
    return { tapInfoOptions, maxTabId };
  }, [search?.tabInfo?.tabs]);

  const currectActiveTab = useMemo(() => {
    return tapInfoOptions.find(item => item.tabNo === params?.activeTab);
  }, [tapInfoOptions]);

  useEffect(() => {
    if (
      !!search?.redirectUrl &&
      !(
        search?.redirectUrl?.includes("products.jsp") ||
        search?.redirectUrl?.includes("pg")
      )
    ) {
      let redirectType = "";
      let localizedRedirectUrl = "";
      if (
        search?.redirectUrl?.startsWith("https") ||
        search?.redirectUrl?.startsWith("http")
      ) {
        redirectType = "windowOpen";
        localizedRedirectUrl = search?.redirectUrl;
      } else if (search?.redirectUrl?.startsWith("de")) {
        redirectType = "windowOpen";
        localizedRedirectUrl = prefix + search?.redirectUrl;
      } else {
        redirectType = "windowOpen";
        localizedRedirectUrl = prefix + search?.redirectUrl;
      }
      useIsoRedirect(localizedRedirectUrl, redirectType, {
        windowOpenType: "_self"
      });
    }
  }, [search]);

  if (search?.skuSearch && !!search?.resultList?.totalNumRecs) {
    const skuSiteIdResult = search?.sku_siteId?.includes(site);
    isSSRToggled || skuSiteIdResult
      ? useIsoRedirect(singleRecordRedirectURL(), "historyPush", { history })
      : location?.replace(singleRecordRedirectURL());
    // return {};
  }

  const fusionPromoDisplay = (search && (search?.saleMemberInfo as any))
    ?.headerConfig?.promoDisplay?.promoDisplay?.MediaText;

  const facetLength = useMemo(() => {
    return (
      booleanFacets.filter(e => e?.refinements?.length !== 0).length +
      Object.keys(refMenu).filter(
        e => refMenu?.[e]?.[0]?.refinements?.length !== 0
      ).length
    );
  }, [booleanFacets, refMenu]);

  const standardMemberSavingsMessage = useMemo(() => {
    return !selectItemsOnSale && !selectSaleItemsOnSale && !allItemsOnSale;
  }, [allItemsOnSale, selectSaleItemsOnSale, selectItemsOnSale]);

  const isStandardMemberSavingsActive = useMemo(() => {
    return (
      productTitle &&
      shouldShowSaleText &&
      (standardMemberSavingsMessage || !activeSaleFilter)
    );
  }, [
    activeSaleFilter,
    productTitle,
    shouldShowSaleText,
    standardMemberSavingsMessage
  ]);

  const isNewConceptTabs =
    (yn(env.FEATURE_SEARCH_ALL_BRANDS) ||
      yn(cookie.FEATURE_SEARCH_ALL_BRANDS)) &&
    !isPGPage &&
    (yn(env.FEATURE_CONCEPT_TABS) || yn(cookie.FEATURE_CONCEPT_TABS));

  const totalSearchResultCount =
    tapInfoOptions.length > 1 && isNewConceptTabs
      ? tapInfoOptions?.reduce((sum, item) => sum + item?.numResults, 0)
      : isSSRToggledWithCSR
      ? search?.resultList?.totalNumRecs || totalNumRecs
      : totalNumRecs;

  const sortedConceptTabs = tapInfoOptions?.sort((a, b) => {
    return (
      DESIRED_CONCEPT_TAB_ORDER.indexOf(a.displayName) -
      DESIRED_CONCEPT_TAB_ORDER.indexOf(b.displayName)
    );
  });

  return {
    seoPageTitle,
    productTitle,
    productList,
    isAemPage,
    prefix,
    params,
    isLoading,
    env,
    isPGPage,
    search,
    tapInfoOptions,
    pageContent,
    navigateToOtherResult,
    smDown,
    contactUsPath,
    isAemEnabled,
    reloadKey,
    shouldShowSaleText,
    standardMemberSavingsMessage,
    activeSaleFilter,
    selectItemsOnSale,
    allItemsOnSale,
    selectSaleItemsOnSale,
    dynamicMemberSavingsText,
    fusionPromoDisplay,
    isSale,
    handleTabClick,
    maxTabId,
    isSSRToggledWithCSR,
    refMenu,
    sortMenu,
    sortingLabel,
    setSortingLabel,
    country,
    totalNumRecs,
    resetRefinement,
    sizeFacets,
    materialFacets,
    selectedRefinementContainer,
    categoryId,
    booleanFacets,
    currectActiveTab,
    gridColumns,
    saveGridCol,
    mdUp,
    facetLength,
    isLazyLoadEnabled,
    isNextPageLoading,
    isSaleFilterEnabled,
    loadMoreData,
    isStockedFilterActive,
    isRefinementFilterActive,
    templateString,
    brand,
    isSort,
    cookie,
    saveSelectedProduct,
    isConcierge,
    seoPageDescription,
    totalSearchResultCount,
    isNewConceptTabs,
    sortedConceptTabs,
    isStandardMemberSavingsActive,
    error
  };
};
