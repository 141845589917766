import { Constants } from "@adobe/aem-react-editable-components";
import React, { FC } from "react";
import memoize from "utils/memoize";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import Router from "app/Router";
import getIsReactRoute from "@RHCommerceDev/utils/getIsReactRoute";
import {
  getAemRootPath,
  isRootPath,
  getBrandFromHostname
} from "utils/aemPathUtils";
import { getLocaleFromPath, IntlPaths } from "@RHCommerceDev/utils/intlUtils";
import { getClientOrigin } from "@RHCommerceDev/utils/getClientOrigin";
import { processEnvServer } from "hooks/useSsrHooks";
import { ModelManager } from "@adobe/aem-spa-page-model-manager";
import { getReqContext } from "utils/reqContext";
import { useFetchModel } from "hooks/useFetchModel";
import isEqual from "react-fast-compare";
import LocationProvider from "custom-providers/LocationProvider";
import {
  globalQueryVariables,
  queryGetAemModelGlobal
} from "graphql-client-queries-get-aem-model";
import { useAppId } from "hooks/useAppId";
import useBrand from "hooks-use-brand";
import { useGetHomeSchema } from "@RHCommerceDev/hooks/useGetSchema";
import { useLocalization } from "hooks/useLocalization";
type AEMPublishRouterProps = {
  $window?: Window;
  model?: unknown;
  client?: any;
  modelClient?: any;
  aemPublishPage?: any;
  path?: string;
  isLocalStorageUpdate?: boolean;
};

type AEMPageRouteProps = RouteProps & {
  resolvePath?: (location: any) => string;
  model?: any;
  AEMPublishPage?: any;
  loading?: boolean;
  brand?: string;
  locale?: string;
  client?: any;
  aemModel?: any;
};

const Page: FC<any> = ({ AEMPublishPage, aemModel, cqPath, ...routeProps }) => {
  const homePage = aemModel?.pageTitle?.toLowerCase()?.includes("home");
  const homeSchema = useGetHomeSchema();
  const prefix = useLocalization();
  const invalidPage = !(
    aemModel?.title ||
    aemModel?.pageTitle ||
    aemModel?.description ||
    aemModel?.pageDesc
  );
  if (invalidPage) {
    return <Redirect to={`${prefix}/error/not-found-error.jsp`} />;
  }
  return (
    <AEMPublishPage
      {...routeProps}
      cqChildren={{}}
      cqItems={aemModel?.[Constants.ITEMS_PROP] ?? {}}
      cqItemsOrder={aemModel?.[Constants.ITEMS_ORDER_PROP] ?? []}
      cqPath={cqPath}
      isInEditor={false}
      pageModel={aemModel}
      homeSchema={homePage ? homeSchema : ""}
    />
  );
};

const AEMPageRoute: FC<AEMPageRouteProps> = React.memo(props => {
  const {
    resolvePath,
    loading,
    brand,
    locale,
    client,
    AEMPublishPage,
    aemModel,
    ...rest
  } = props;

  return !loading && aemModel ? (
    <Route
      {...rest}
      render={routeProps => {
        const routePath = rest.path as string;
        const path =
          aemModel && aemModel[":path"]
            ? aemModel[":path"]
            : routeProps.location;
        let cqPath = resolvePath
          ? resolvePath(path ? (path.pathname ? path.pathname : path) : "")
          : "";
        if (cqPath === "") {
          cqPath = "/";
        }
        if (isRootPath(cqPath)) {
          cqPath = getAemRootPath(brand, locale);
        }
        try {
          return (
            <>
              <LocationProvider path={routePath} client={client}>
                <Page
                  AEMPublishPage={AEMPublishPage}
                  aemModel={aemModel}
                  cqPath={cqPath}
                  {...rest}
                />
              </LocationProvider>
            </>
          );
        } catch (err) {
          return <>{err}</>;
        }
      }}
    />
  ) : (
    <></>
  );
}, isEqual);

const AEMPublishRouter: FC<AEMPublishRouterProps> = ({
  $window = window as any,
  client,
  modelClient,
  path,
  ...rest
}) => {
  const AEMPublishPage = rest.aemPublishPage;
  if (!processEnvServer) {
    dispatchEvent(new Event("load"));
  }
  const location = useLocation();

  const SSRCachedModel =
    !processEnvServer && (window as any).__APOLLO_STATE__
      ? client.readQuery({
          query: queryGetAemModelGlobal,
          variables: globalQueryVariables(path)
        })
      : false;
  if (SSRCachedModel) {
    modelClient.setModel(SSRCachedModel);
  }
  const { pageContent, loading } = useFetchModel(
    location.pathname,
    location.pathname.includes(".jsp"),
    true,
    SSRCachedModel ? SSRCachedModel : null,
    client
  );
  if (pageContent) {
    modelClient.setModel(pageContent);
  }
  const originSSR = getClientOrigin();
  const originHost = processEnvServer ? originSSR : $window.location.hostname;
  const expressReq = getReqContext();
  const req: { path?: string; url?: string } = processEnvServer
    ? getReqContext()
    : {};
  const { locale } = getLocaleFromPath(originHost) || {
    locale: IntlPaths[0]
  };
  const { isConcierge } = useAppId();
  const brand = isConcierge ? useBrand() : getBrandFromHostname(originHost);
  const rawPath = processEnvServer ? req.path : location.pathname;
  const modelPath = isRootPath(rawPath || "")
    ? getAemRootPath(brand, locale)
    : rawPath;
  ModelManager.initializeAsync({
    path: modelPath,
    modelClient,
    model: SSRCachedModel ? SSRCachedModel : pageContent ? pageContent : {}
  });
  const aemModel = pageContent;

  const pagePath =
    aemModel && aemModel[":path"] ? aemModel[":path"] : location.pathname;

  const pdpRouter = processEnvServer
    ? req?.path?.includes("/actuator/info")
    : false;

  if (getIsReactRoute() || pdpRouter) {
    return (
      <LocationProvider path={pagePath} client={client}>
        <Router
          extraRoutes={[]}
          isLocalStorageUpdate={rest.isLocalStorageUpdate}
        />{" "}
      </LocationProvider>
    );
  } else {
    return (
      <LocationProvider path={pagePath} client={client}>
        <Router
          extraRoutes={[
            <AEMPageRoute
              key={`${pagePath}.html`}
              exact
              path={`${pagePath}.html`}
              resolvePath={() => pagePath?.replace(/\.html$/, "") || ""}
              AEMPublishPage={AEMPublishPage}
              loading={loading}
              aemModel={aemModel}
              brand={brand}
              locale={locale}
              client={client}
            />,

            <AEMPageRoute
              key={location.pathname}
              exact
              path={location.pathname}
              resolvePath={() => pagePath || ""}
              AEMPublishPage={AEMPublishPage}
              loading={loading}
              aemModel={aemModel}
              brand={brand}
              locale={locale}
              client={client}
            />
          ]}
          isEditor={false}
          isLocalStorageUpdate={rest.isLocalStorageUpdate}
        />
      </LocationProvider>
    );
  }
};

export default memoize(AEMPublishRouter);
