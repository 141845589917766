import * as React from "react";
import useEmblaCarousel, {
  type UseEmblaCarouselType
} from "embla-carousel-react";
import { cn } from "utils/cn";
import DotsCarousel from "@RHCommerceDev/component-product-grid-image-carousel/DotsCarousel";
import Button from "components/Button";
import { usePrevNextButtons } from "./EmblaCarouselArrowButtons";
import { useAutoScroll } from "./EmblaCarouselAutoScroll";

/* 

NOTE: PLEASE DON'T DIRECTLY UPDATE ANY THING IN THIS FILE. THIS FILE IS BEEN USED IN MULTIPLE PLACES.
1. OLD PDP thumbnail carousel
2. SBR carousel
3. YAML carousel

*/

export interface OptionsType {
  // other properties
  speed?: number; // Add this line
}

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

export function stopDefaultPropagation(e) {
  e.stopPropagation();
  e.preventDefault();
}

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: "horizontal" | "vertical";
  setApi?: (api: CarouselApi) => void;
  slideToShow?: number;
  scrollToSlide?: number;
  carouselLength: number;
  scrollSpeed?: number;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  canScrollPrev: boolean;
  canScrollNext: boolean;
  scrollToSlide?: number;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
  onAutoScrollButtonClick: (callback: () => void) => void;
  toggleAutoScroll: () => void;
  autoScrollIsPlaying: boolean;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = "horizontal",
      opts,
      setApi,
      plugins,
      className,
      children,
      scrollToSlide,
      carouselLength,
      slideToShow = 1,
      ...props
    },
    ref
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        axis: orientation === "horizontal" ? "x" : "y",
        ...opts
      },
      plugins
    );
    const { onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(api, {
      slideToShow
    });

    const { autoScrollIsPlaying, toggleAutoScroll, onAutoScrollButtonClick } =
      useAutoScroll(api);
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);

    const onSelect = React.useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    React.useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (!api) {
        return;
      }

      if (scrollToSlide) {
        api.scrollTo(scrollToSlide);
      }

      onSelect(api);

      api.on("reInit", onSelect);
      api.on("select", onSelect);

      return () => {
        api?.off("select", onSelect);
      };
    }, [api, onSelect, scrollToSlide]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation:
            orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          canScrollPrev,
          canScrollNext,
          scrollToSlide,
          carouselLength,
          onPrevButtonClick,
          onNextButtonClick,
          onAutoScrollButtonClick,
          toggleAutoScroll,
          autoScrollIsPlaying
        }}
      >
        <div
          ref={ref}
          className={cn("relative", className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  }
);
Carousel.displayName = "Carousel";

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div ref={carouselRef} className="overflow-hidden">
      <div
        ref={ref}
        className={cn(
          "flex",
          orientation === "horizontal" ? "-ml-4" : "-mt-4 flex-col",
          className
        )}
        {...props}
      />
    </div>
  );
});
CarouselContent.displayName = "CarouselContent";

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { orientation } = useCarousel();

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn(
        "min-w-0 shrink-0 grow-0 basis-full",
        orientation === "horizontal" ? "pl-4" : "pt-4",
        className
      )}
      {...props}
    />
  );
});
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ className, children, ...props }, ref) => {
  const {
    orientation,
    canScrollPrev,
    onPrevButtonClick,
    onAutoScrollButtonClick
  } = useCarousel();

  const handlePrevClick = React.useCallback(
    e => {
      onAutoScrollButtonClick(onPrevButtonClick);
    },
    [onAutoScrollButtonClick, onPrevButtonClick]
  );

  return (
    <button
      ref={ref}
      className={cn(
        "absolute  h-8 w-8",
        orientation === "horizontal"
          ? "-left-12 top-1/2 -translate-y-1/2"
          : "-top-12 left-1/2 -translate-x-1/2 rotate-90",
        !canScrollPrev && "opacity-20",
        className
      )}
      disabled={!canScrollPrev}
      onClick={handlePrevClick}
      {...props}
    >
      {children}
      <span className="sr-only">Previous slide</span>
    </button>
  );
});

CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ className, children, ...props }, ref) => {
  const {
    orientation,
    canScrollNext,
    onNextButtonClick,
    onAutoScrollButtonClick
  } = useCarousel();

  const handleNextClick = React.useCallback(
    e => {
      onAutoScrollButtonClick(onNextButtonClick);
    },
    [onAutoScrollButtonClick, onNextButtonClick]
  );

  return (
    <button
      ref={ref}
      className={cn(
        "absolute h-8 w-8 !bg-transparent",
        orientation === "horizontal"
          ? "-right-12 top-1/2 -translate-y-1/2"
          : "-bottom-12 left-1/2 -translate-x-1/2 rotate-90",
        !canScrollNext && "opacity-20",
        className
      )}
      disabled={!canScrollNext}
      onClick={handleNextClick}
      {...props}
    >
      {children}
      <span className="sr-only">Next slide</span>
    </button>
  );
});

CarouselNext.displayName = "CarouselNext";

const CarouselDots = React.forwardRef<
  HTMLElement,
  React.ComponentProps<typeof Button>
>((props, _) => {
  const { carouselLength, scrollToSlide = 1 } = useCarousel();

  return (
    <div className="grid justify-center">
      <DotsCarousel
        length={carouselLength}
        maxVisibleIndicators={5}
        current={scrollToSlide}
        {...props}
      />
    </div>
  );
});

CarouselDots.displayName = "CarouselDots";

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselDots
};
