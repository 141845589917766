import { createStyles, makeStyles, Theme } from "utils/material-ui-core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerContainer: {
      height: "100%"
    },

    imageColumn: {
      height: "100%",
      paddingRight: theme.spacing(5),
      [theme.breakpoints.up("xl")]: {
        paddingRight: theme.spacing(10)
      }
    },
    listItem: {
      marginBottom: 16,
      padding: "0px !important",
      textUnderlineOffset: "5px",
      textDecorationColor: "rgba(0, 0, 0, 1) !important",
      transition: "text-decoration-color 700ms",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline"
      }
    },
    padding0: {
      padding: "0px !important"
    },
    ssrListItem: {
      marginBottom: `${theme.spacing(1.5)} !important`,
      padding: `0px !important`,
      textUnderlineOffset: "5px !important",
      textDecorationColor: "rgba(0, 0, 0, 1) !important",
      transition: "text-decoration-color 700ms !important",
      "&:hover": {
        cursor: "pointer !important"
      }
    },
    conceptListItem: {
      "&:nth-last-child(2)": {
        //marginTop: theme.spacing(5)
      }
    },
    grandchildListItem: {
      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: "rgba(0, 0, 0, 1)"
      }
    },
    imageContainer: {
      height: "100%"
    },
    imageView: {
      alignItems: "flex-start",
      justifyContent: "flex-end"
    },
    closeIconContainer: {
      position: "absolute",
      top: theme.spacing(3.5),
      right: theme.spacing(1.5),
      [theme.breakpoints.up("xl")]: {
        top: theme.spacing(7.5),
        right: theme.spacing(3.5)
      }
    },
    closeIcon: {
      fontSize: theme.fontSizes.caption,
      [theme.breakpoints.up("xl")]: {
        fontSize: theme.fontSizes.xl
      }
    },
    uppercase: {
      textTransform: "uppercase"
    },
    capitalize: {
      textTransform: "capitalize"
    },
    extraLineHeight: {
      lineHeight: 2
    },
    ssrExtraLineHeight: {
      lineHeight: "1.45 !important"
    },
    reveal: {
      opacity: 0
    },

    fadeInUp: {
      animationDuration: "0s",
      animationTimingFunction: "easeIn",
      animationDelay: "0s",
      animationIterationCount: 1,
      animationDirection: "normal",
      animationFillMode: "none",
      animationPlayState: "running",
      animationName: "fadeInUp"
    },
    selected: {
      textDecorationLine: "underline",
      textDecorationColor: "rgba(0, 0, 0, 1)"
    },
    onHover: {
      textUnderlineOffset: "5px",
      "&:hover": {
        cursor: "pointer",
        textDecorationLine: "underline",
        textDecorationColor: "rgba(0, 0, 0, 1)"
      }
    },
    ssrMarginBottom: { marginBottom: "16px !important" },
    colorRed: { color: "#CA2022", textDecorationColor: "#CA2022" }
  })
);
