import { useQuery } from "@apollo/client";
import { queryMemberPrice } from "@RHCommerceDev/graphql-client/queries/member-price";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";

export const memberPriceAtom = atom<{
  memberPrice?: number;
  loading?: boolean;
  exec?: boolean;
}>({});

const useMemberPrice = (country, brand) => {
  const atomValue = useAtomValue(memberPriceAtom);
  const setAtom = useSetAtom(memberPriceAtom);

  const { data, loading } = useQuery<Query>(queryMemberPrice, {
    skip: !(country && brand && !atomValue?.memberPrice && !atomValue?.exec),
    variables: {
      country,
      brand
    },
    onCompleted: data => {
      setAtom({
        memberPrice: data?.memberPrice,
        loading: false,
        exec: true
      });
    }
  });

  useEffect(() => {
    if (loading && !atomValue?.loading) {
      setAtom({ loading: true });
    }
  }, [loading]);

  const memberPrice = useMemo(() => {
    return atomValue?.memberPrice ?? data?.memberPrice ?? null;
  }, [JSON.stringify(atomValue), data?.memberPrice]);

  return {
    data: { memberPrice },
    memberPrice: memberPrice,
    loading
  };
};

export default useMemberPrice;
