import { processEnvServer } from "hooks/useSsrHooks";
import { memoryStorage } from "../storage";
import { AnalyticsEventDetail } from "../types";
import { setStorageValue } from "hooks/useCookiesWithPermission";

export const addCatalogToMemoryForDesktop = (data: any) => {
  const { target } = data;
  let catalog = [
    {
      id: target?.dataset?.analyticsTopcatId,
      name: target?.dataset?.analyticsTopcatName
    },
    {
      id: target?.dataset?.analyticsParentcatId,
      name: target?.dataset?.analyticsParentcatName
    },
    {
      id: target?.dataset?.analyticsCategoryId,
      name: target?.dataset?.analyticsCategoryName
    }
  ];
  memoryStorage.setItem("analytics-catalog", catalog);
};

export const addCatalogToLocalStorage = (detail: AnalyticsEventDetail) => {
  let catalogArray = [];
  const itemData = {
    id: detail.data.local?.item?.id,
    name: detail.data.local?.item?.displayName
  };
  const catalog =
    !processEnvServer && localStorage.getItem("analytics-catalog");

  if (catalog) {
    catalogArray = JSON.parse(catalog);
    if (
      catalogArray.length === 1 &&
      catalogArray[0]?.name?.toLowerCase() === "sale"
    ) {
      let _itemData = Object.assign(itemData);
      _itemData.name = `Sale-${itemData.name}`;
      catalogArray[0] = _itemData;
    } else {
      catalogArray.push(itemData);
    }
    if (!processEnvServer) {
      setStorageValue({
        storageKey: "analytics-catalog",
        value: JSON.stringify(catalogArray)
      });
    }
  } else {
    catalogArray.push(itemData);
    if (!processEnvServer) {
      setStorageValue({
        storageKey: "analytics-catalog",
        value: JSON.stringify(catalogArray)
      });
    }
  }
};

export const addCatalogToLocalStorageForDesktop = (
  detail: AnalyticsEventDetail
) => {
  let catalogArray = [];
  const category = detail.data.local?.category;
  // TODO: Check if the code commented is really necessary
  // const item = detail.data.local?.navElement?.displayName;
  const itemData = {
    id: detail.data.local?.navElement?.id,
    name: detail.data.local?.navElement?.displayName
  };
  const catalog =
    !processEnvServer && localStorage.getItem("analytics-catalog");
  if (catalog) {
    catalogArray = JSON.parse(catalog);
    if (!processEnvServer && catalogArray.length < 3) {
      if (category === "topCat") {
        catalogArray[0] = itemData;
        setStorageValue({
          storageKey: "analytics-catalog",
          value: JSON.stringify(catalogArray)
        });
      } else if (category === "parentCat") {
        catalogArray[1] = itemData;
        setStorageValue({
          storageKey: "analytics-catalog",
          value: JSON.stringify(catalogArray)
        });
      } else if (category === "cat") {
        catalogArray[2] = itemData;
        setStorageValue({
          storageKey: "analytics-catalog",
          value: JSON.stringify(catalogArray)
        });
      }
    }
  } else {
    if (!processEnvServer && category === "topCat") {
      catalogArray[0] = itemData;
      setStorageValue({
        storageKey: "analytics-catalog",
        value: JSON.stringify(catalogArray)
      });
    } else if (!processEnvServer && category === "parentCat") {
      catalogArray[1] = itemData;
      setStorageValue({
        storageKey: "analytics-catalog",
        value: JSON.stringify(catalogArray)
      });
    }
  }
};

export const handleBackNavForCatalog = (detail: AnalyticsEventDetail) => {
  const type = detail.data.local?.type;
  const catalog =
    !processEnvServer && localStorage.getItem("analytics-catalog");
  if (catalog) {
    let tempArr = JSON.parse(catalog);
    if (!processEnvServer && tempArr.length < 3) {
      if (type === "mob") {
        let _cat = tempArr.pop();
        if (
          _cat?.name?.toLowerCase().includes("sales-") &&
          tempArr.length === 0
        ) {
          // For maintaining the SALES Category appending.
          const _SaleItem = [{ name: "SALE" }];
          setStorageValue({
            storageKey: "analytics-catalog",
            value: JSON.stringify(_SaleItem)
          });
        } else {
          setStorageValue({
            storageKey: "analytics-catalog",
            value: JSON.stringify(tempArr)
          });
        }
      } else if (type === "desk") {
        localStorage.removeItem("analytics-catalog");
      }
    }
  }
};

export const addTypeaheadSearchTerm = (detail: AnalyticsEventDetail) => {
  const item = detail.data.local?.searchTerm;
  if (!processEnvServer) {
    if (item.product) {
      setStorageValue({
        storageKey: "analytics-searchtermclick",
        value: `${item.product.displayName}`
      });
    } else if (item.properties.parentCategoryName) {
      setStorageValue({
        storageKey: "analytics-searchtermclick",
        value: `${item.label} in ${item.properties.parentCategoryName}`
      });
    } else {
      setStorageValue({
        storageKey: "analytics-searchtermclick",
        value: `${item.label}`
      });
    }
  }
};

export const clearLocalStorage = () => {
  if (!processEnvServer) {
    localStorage.removeItem("analytics-access");
    localStorage.removeItem("analytics-navigatingFromSearch");
    localStorage.removeItem("analytics-catalog");
    localStorage.removeItem("analytics-category");
    localStorage.removeItem("analytics-searchtermclick");
    localStorage.removeItem("analytics-categoryForProduct");
    localStorage.removeItem("fusion_data");
    localStorage.removeItem("analytics-emptyProduct");
    localStorage.removeItem("analytics-search");
    localStorage.removeItem("analytics-catId");
    localStorage.removeItem("analytics-previous-url");
    localStorage.removeItem("analytics-navCatalog");
    localStorage.removeItem("analytics-category-title");
    localStorage.removeItem("analytics-product-data");
    localStorage.removeItem("analytics-searchbar-enter");
    localStorage.removeItem("isLoggedin");
    localStorage.removeItem("rh_open_tabs");
    localStorage.removeItem("app_rh_open_tabs");
  }
};
