import { atom, useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";

export const currentCartIdAtom = atom<string>("");
export const useCurrentCartId = () => {
  const currentCartId = useAtomValue(currentCartIdAtom);
  return useMemo(() => currentCartId, [currentCartId]);
};
export const useSetCurrentCartId = () => useSetAtom(currentCartIdAtom);

export default useCurrentCartId;
