import useDidMountEffect from "hooks/useDidMountEffect";
import useIsIOSDevice from "hooks/useIsIOS";
import React, { FC, useEffect } from "react";
import { RHR_DRAWER_TRANSITION_DURATION } from "utils/constants";
import { DrawerProps, Drawer as MuiDrawer } from "utils/material-ui-core";
import memoize from "utils/memoize";
import { disable, enable, setInitialDocumentOverFlow } from "utils/scrollLock";

interface NewPDPProps {}

type CombinedProps = DrawerProps & NewPDPProps;

const Drawer: FC<CombinedProps> = props => {
  const { open } = props;
  const isIOSDevice = useIsIOSDevice();

  useEffect(() => {
    setInitialDocumentOverFlow();
  }, []);

  useDidMountEffect(() => {
    if (!isIOSDevice) return;

    if (open) {
      enable?.();
    }

    return () => disable();
  }, [open]);

  return (
    <MuiDrawer
      id={props?.id || "component-drawer"}
      transitionDuration={RHR_DRAWER_TRANSITION_DURATION}
      {...props}
    />
  );
};

export default memoize(Drawer);
