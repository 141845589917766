import { useApolloClient } from "@apollo/client";
import { queryCartProjection } from "@RHCommerceDev/graphql-client-cart-broker/queries/cart-projection";
import {
  useCartProjectionSetAtom,
  useRhUserAtomValue
} from "@RHCommerceDev/hooks-atoms";
import { useCallback } from "react";
import { useEnv } from "hooks/useEnv";
import { useSetCurrentCartId } from "@RHCommerceDev/hooks-atoms/useCurrentCartId";

export const useGetCartProjectLazyQuery = () => {
  const client = useApolloClient();

  const { id, email } = useRhUserAtomValue();
  const setCartProjectionAtom = useCartProjectionSetAtom();
  const setCurrentCartId = useSetCurrentCartId();
  const env = useEnv();
  const getCartProjectionWrapper = useCallback(
    async (variables = {}) => {
      /** if user id is not defined don't allow this call */
      if (!Boolean(variables?.id ?? id) && !env?.FEATURE_CART_ID_ATOM) {
        return;
      }
      try {
        setCartProjectionAtom(prev => ({ ...prev, loading: true }));
        const { data } = await client?.query({
          query: queryCartProjection,
          variables: {
            /** empty userId should throw graphql error */
            userId: variables?.id ?? id ?? "",
            email: variables?.email ?? email ?? null,
            // queryTimeStamp is added to ensure to the data is not coming from disk cache
            queryTimeStamp: String(Date.now())
          }
        });

        setCartProjectionAtom({ ...data?.cartProjection, loading: false });
        if (data?.cartProjection?.id)
          setCurrentCartId(data?.cartProjection?.id);
      } catch (err) {
        setCartProjectionAtom(prev => ({ ...prev, loading: false }));
        console?.error(err);
      }
    },
    [
      client,
      id,
      email,
      setCartProjectionAtom,
      setCurrentCartId,
      env?.FEATURE_CART_ID_ATOM
    ]
  );

  return [getCartProjectionWrapper] as const;
};

export default useGetCartProjectLazyQuery;
