import useIsoRedirect from "@RHCommerceDev/hooks/useIsoRedirect";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import buildPath, { isForcedReloadPath } from "@RHCommerceDev/utils/buildPath";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import memoize from "@RHCommerceDev/utils/memoize";
import {
  Grid,
  List,
  ListItem,
  Typography
} from "@RHCommerceDev/utils/material-ui-core";
import { useStyles } from "@RHCommerceDev/container-rhr-header/RHRCatalogNavigationDetails/style";
import yn from "yn";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useAppId } from "@RHCommerceDev/hooks/useAppId";
import classNames from "classnames";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { isSSRToggledCheck } from "@RHCommerceDev/utils/ssrHelpers";
import uniqBy from "lodash.uniqby";
import RHLink from "@RHCommerceDev/component-rh-link";
import { useCookies } from "react-cookie"; //for concierge navigation
import {
  SELECTED_BRAND_COOKIE,
  BRAND_NAME_TO_BRAND_CODE
} from "@RHCommerceDev/utils/constants";

export interface RHRSaleNavigationProps {
  selectedCatalogNavItem: NavigationElement;
  onCategoryDrawerClose: () => void;
  navigateToUrl?: (navigationItem: NavigationElement) => void;
  isSelected?: boolean;
}

export const RHRSaleNavigation: FC<RHRSaleNavigationProps> = ({
  selectedCatalogNavItem,
  onCategoryDrawerClose,
  navigateToUrl,
  isSelected
}) => {
  const classes = useStyles();
  const history = useHistory();
  const env = useEnv();
  const { isConcierge } = useAppId();
  const prefix = useLocalization();
  const isSSRToggled = isSSRToggledCheck();
  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);
  const FEATURE_FORCE_RELOAD_PATHS = isSSRToggled;
  const [selectedChildCategoryIndex, setSelectedChildCategoryIndex] = useState<
    number | undefined
  >(isConcierge ? 0 : undefined);
  const [
    selectedSecondaryChildCategoryIndex,
    setSelectedSecondaryChildCategoryIndex
  ] = useState<number | undefined>(isConcierge ? 0 : undefined);
  const { getResult } = isForcedReloadPath("");
  const [, setCookie] = useCookies([SELECTED_BRAND_COOKIE]);

  useEffect(() => {
    setSelectedChildCategoryIndex(isConcierge ? 0 : undefined); //resetting child category selection when a different concept is selected
    setSelectedSecondaryChildCategoryIndex(isConcierge ? 0 : undefined);
  }, [isConcierge, isSelected]);

  const handleSelectChildCategory = useCallback(
    (event: React.MouseEvent, item, index) => {
      if (item?.childCategories?.length) {
        setSelectedChildCategoryIndex(index);
      } else {
        const target = event.target as HTMLElement;
        const id = (target?.parentNode as HTMLElement)?.id;
        onCategoryDrawerClose();
        if (!processEnvServer) {
          document?.body?.dispatchEvent(
            new CustomEvent("cta_click", {
              detail: {
                item: {
                  isHeaderNavigation: true,
                  topCat: selectedCatalogNavItem?.displayName,
                  parentCat: item?.displayName,
                  cat: item?.targetUrl,
                  catURL: item?.targetUrl,
                  class: target?.className,
                  id: target?.id || id
                }
              }
            })
          );
        }
        if (item?.linkType === "anchor") {
          onCategoryDrawerClose();
          if (typeof window !== "undefined") {
            const formattedUrl = item?.targetUrl?.startsWith("https://")
              ? item?.targetUrl
              : `https://${item?.targetUrl}`;
            window.open(formattedUrl, "_blank");
          }
        } else {
          history.push(buildPath(`${prefix}${item?.targetUrl}`));
        }
      }
    },
    [history, selectedChildCategoryIndex]
  );

  //this function is used for concierge navigation
  const handleSelectChildCategoryConcierge = useCallback(
    (item, index) => {
      if (item?.childCategories?.length) {
        setSelectedChildCategoryIndex(index);
      } else {
        onCategoryDrawerClose();
        if (item?.linkType === "anchor") {
          onCategoryDrawerClose();
          if (typeof window !== "undefined") {
            const brand = BRAND_NAME_TO_BRAND_CODE?.[item?.id?.toUpperCase?.()];

            if (brand) {
              setCookie(SELECTED_BRAND_COOKIE, brand, {
                domain: `${env.ENV_DOMAIN}`,
                path: "/"
              });
            }
            const url = prefix?.endsWith("/") ? "sale" : "/sale";
            window.location.href = `${prefix}${url}`;
          }
        } else {
          history.push(buildPath(`${prefix}${item?.targetUrl}`));
        }
      }
    },
    [history, onCategoryDrawerClose, prefix, setCookie]
  );

  const handleSelectSecondaryChildCategory = useCallback(
    (event: React.MouseEvent, item, index) => {
      if (item?.childCategories?.length) {
        setSelectedSecondaryChildCategoryIndex(index);
      } else {
        onCategoryDrawerClose();
        if (!processEnvServer) {
          const target = event.target as HTMLElement;
          const id = (target?.parentNode as HTMLElement)?.id;
          document?.body?.dispatchEvent(
            new CustomEvent("cta_click", {
              detail: {
                item: {
                  isHeaderNavigation: true,
                  topCat: selectedCatalogNavItem?.displayName,
                  parentCat:
                    selectedCatalogNavItem?.childCategories &&
                    selectedCatalogNavItem?.childCategories[
                      selectedChildCategoryIndex ?? 0
                    ]?.displayName,
                  cat: item?.displayName,
                  catURL: item.targetUrl,
                  class: target?.className,
                  id: target?.id || id
                }
              }
            })
          );
        }
        if (item?.linkType === "anchor") {
          onCategoryDrawerClose();
          if (typeof window !== "undefined") {
            const formattedUrl = item?.targetUrl?.startsWith("https://")
              ? item?.targetUrl
              : `https://${item?.targetUrl}`;
            window.open(formattedUrl, "_blank");
          }
        } else {
          history.push(buildPath(`${prefix}${item?.targetUrl}`));
        }
      }
    },
    [history, selectedSecondaryChildCategoryIndex, selectedChildCategoryIndex]
  );

  const trackAnalyticsClick = (
    event: React.MouseEvent,
    navigationItem: NavigationElement
  ) => {
    if (!processEnvServer) {
      const target = event.target as HTMLElement;
      const id = (target?.parentNode as HTMLElement)?.id;
      document?.body?.dispatchEvent(
        new CustomEvent("cta_click", {
          detail: {
            item: {
              isHeaderNavigation: true,
              topCat:
                selectedCatalogNavItem?.displayName +
                "/" +
                (selectedCatalogNavItem?.childCategories &&
                  selectedCatalogNavItem?.childCategories[
                    selectedChildCategoryIndex ?? 0
                  ].displayName),
              parentCat:
                selectedCatalogNavItem?.childCategories &&
                selectedCatalogNavItem?.childCategories[
                  selectedChildCategoryIndex ?? 0
                ]?.childCategories[selectedSecondaryChildCategoryIndex]
                  .displayName,
              cat: navigationItem?.displayName,
              catURL: navigationItem?.targetUrl,
              class: target?.className,
              id: target?.id || id
            }
          }
        })
      );
    }
  };

  const handleNavigationClick = useCallback(
    (navigationItem: NavigationElement) => {
      if (navigationItem.linkType === "none") {
        onCategoryDrawerClose();
        return useIsoRedirect(navigationItem?.targetUrl!, "windowOpen");
      }
      if (navigationItem.targetUrl?.trim().indexOf("http") === 0) {
        navigateToUrl?.(navigationItem);
      } else {
        onCategoryDrawerClose();
        const basePath = buildPath(
          FEATURE_INTERNATIONAL
            ? `${prefix}${navigationItem.targetUrl}`
            : `${navigationItem.targetUrl}`,
          {
            sale: "true",
            fromNav:
              navigationItem?.targetUrl?.includes("new-results") ||
              navigationItem?.targetUrl?.includes("results")
                ? "true"
                : null
          }
        );
        const params = {} as any;
        basePath.split("&").map(item => {
          const [key, value] = item.split("=");
          return (params[key] = value);
        });
        /*
               the SSR pages (PG/CG/Search/PDP) should do a full page load to load from the server and
               redis cache instead of using the react router and loading on the client.
              */
        if (isSSRToggled || getResult(basePath)) {
          window.location.href = basePath;
        } else {
          history.push(basePath);
        }
      }
    },
    [
      history,
      navigateToUrl,
      onCategoryDrawerClose,
      prefix,
      selectedCatalogNavItem,
      selectedChildCategoryIndex,
      FEATURE_FORCE_RELOAD_PATHS,
      FEATURE_INTERNATIONAL
    ]
  );
  const allMenuItemCategories = useMemo(() => {
    const categories = selectedCatalogNavItem?.childCategories?.reduce(
      (acc, value) => {
        return [...acc, ...(value?.childCategories ?? [])];
      },
      []
    );
    return uniqBy(categories, "targetUrl");
  }, [selectedCatalogNavItem?.childCategories]);

  return (
    <div>
      <Grid container id="rhr-sale-navigation-dropdown" className="flex-nowrap">
        <Grid
          item
          id={"sale-nav-dropdown"}
          className="pr-[40px] border-r-[0.5px] border-r-[#E1E1E1]"
        >
          <List
            className="w-[178px]"
            disablePadding
            role="menu"
            aria-label={selectedCatalogNavItem?.displayName ?? ""}
          >
            {selectedCatalogNavItem?.childCategories?.map((item, index) => {
              const isSelected = selectedChildCategoryIndex === index;
              return (
                <ListItem
                  id={`container-rhr-saleNavigation_sale-navigation-${item.id}`}
                  className={classNames(
                    "underline-offset-[4px] !decoration-[rgba(0,0,0,1)] mb-[16px] hover:underline hover:cursor-pointer duration-700",
                    classes.padding0,
                    FEATURE_FORCE_RELOAD_PATHS ? "!mb-[12px]" : ""
                  )}
                  role="none"
                  key={`sale-navigation-${item.id}`}
                >
                  <Typography
                    className={classNames([
                      "!leading-[16px]",
                      "capitalize",
                      FEATURE_FORCE_RELOAD_PATHS ? "!leading-[1.45]" : "",
                      {
                        ["underline decoration-[rgba(0,0,0,1)]"]:
                          isSelected && isConcierge
                      }
                    ])}
                    style={
                      selectedChildCategoryIndex == null || isSelected
                        ? { color: "#000000" }
                        : { color: "#898886" }
                    }
                    variant="caption"
                    onMouseEnter={
                      isConcierge
                        ? () => setSelectedChildCategoryIndex(index)
                        : undefined
                    }
                    onClick={
                      isConcierge
                        ? () => handleSelectChildCategoryConcierge(item, index)
                        : e => handleSelectChildCategory(e, item, index)
                    }
                    dangerouslySetInnerHTML={{
                      __html: item?.displayName?.replace(/<br>/g, "")
                    }}
                    role="menuitem"
                    aria-label={`${item?.displayName?.replace(/<br>/g, "")}`}
                    aria-haspopup={item.childCategories?.length > 0}
                    aria-expanded={isSelected}
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid
          className={`pl-[40px] ${
            selectedChildCategoryIndex === undefined ? "hidden" : ""
          }`}
        >
          <List
            className="w-[178px]"
            disablePadding
            role="menu"
            aria-label={
              selectedCatalogNavItem?.childCategories?.[
                selectedChildCategoryIndex ?? 0
              ]?.displayName ?? ""
            }
          >
            {(selectedChildCategoryIndex !== undefined
              ? selectedCatalogNavItem?.childCategories?.[
                  selectedChildCategoryIndex ?? 0
                ]?.childCategories
              : allMenuItemCategories
            )?.map((item, index) => {
              const isCategorySelected =
                selectedSecondaryChildCategoryIndex === index;
              const hasSecondarySubCategories = !!item?.childCategories?.length;
              return (
                <RHLink
                  {...(!hasSecondarySubCategories && {
                    to: `${prefix}${item?.targetUrl}`
                  })}
                  id={`container-rhr-saleNavigation_sale-navigation-category-${item.id}`}
                  className={classNames(
                    "w-full flex relative box-border text-left items-center py-[5px] justify-start text-decoration",
                    "underline-offset-[4px] !decoration-[rgba(0,0,0,1)] mb-[16px] no-underline hover:underline hover:cursor-pointer duration-700",
                    classes.padding0,
                    FEATURE_FORCE_RELOAD_PATHS ? "!mb-[12px]" : ""
                  )}
                  key={`sale-navigation-category${item.id}`}
                  role="menuitem"
                  aria-label={`${item?.displayName.replace(/<br>/g, "")}`}
                >
                  <Typography
                    className={classNames([
                      "!leading-[16px] ",
                      "capitalize",

                      FEATURE_FORCE_RELOAD_PATHS ? "!leading-[1.45]" : "",
                      {
                        ["underline decoration-[rgba(0,0,0,1)]"]:
                          isCategorySelected && isConcierge
                      }
                    ])}
                    style={
                      isCategorySelected
                        ? {
                            textDecoration: "underline"
                          }
                        : {}
                    }
                    variant="caption"
                    onMouseEnter={
                      isConcierge
                        ? () => setSelectedSecondaryChildCategoryIndex(index)
                        : undefined
                    }
                    onClick={e =>
                      handleSelectSecondaryChildCategory(e, item, index)
                    }
                    dangerouslySetInnerHTML={{
                      __html: item?.displayName.replace(/<br>/g, "")
                    }}
                    role="text"
                  />
                </RHLink>
              );
            })}
          </List>
        </Grid>

        {selectedSecondaryChildCategoryIndex !== undefined ? (
          <Grid
            className={classNames("pl-[40px]", {
              "border-l-[0.5px] border-l-[#E1E1E1]": !isConcierge
            })}
          >
            <List className="w-[130px]" disablePadding role="menu">
              {selectedSecondaryChildCategoryIndex !== undefined &&
                selectedCatalogNavItem?.childCategories?.[
                  selectedChildCategoryIndex ?? 0
                ]?.childCategories?.[
                  selectedSecondaryChildCategoryIndex ?? 0
                ]?.childCategories?.map(item => {
                  return (
                    <ListItem
                      id={`container-rhr-saleNavigation_navigation-${item.id}`}
                      data-testid={`container-rhr-saleNavigation_navigation-${item.id}`}
                      key={`navigation-${item.id}`}
                      className={classNames(
                        "underline-offset-[4px] !decoration-[rgba(0,0,0,1)] mb-[16px] hover:underline hover:cursor-pointer duration-700",
                        classes.padding0,
                        FEATURE_FORCE_RELOAD_PATHS ? "!mb-[16px]" : ""
                      )}
                      onClick={e => {
                        handleNavigationClick(item);
                        trackAnalyticsClick(e, item);
                      }}
                      role="none"
                    >
                      <Typography
                        variant="caption"
                        className={classNames(
                          "capitalize",
                          FEATURE_FORCE_RELOAD_PATHS
                            ? "!leading-[1.45]"
                            : "leading-loose"
                        )}
                        onClick={() => handleNavigationClick(item)}
                        dangerouslySetInnerHTML={{
                          __html: item.displayName.replace(/<br>/g, "")
                        }}
                        role="menuitem"
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export const RHRSaleNavigationWrapper: FC<RHRSaleNavigationProps> = props => {
  const navigateToUrl = (navigationItem: NavigationElement) => {
    useIsoRedirect(navigationItem?.targetUrl, "windowAssign");
  };

  return <RHRSaleNavigation navigateToUrl={navigateToUrl} {...props} />;
};

export default memoize(RHRSaleNavigationWrapper);
