import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import classNames from "classnames";
import { useCookies } from "react-cookie";
import RHCloseIcon from "@RHCommerceDev/icon-close";
import yn from "yn";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import { NA_COUNTRIES } from "@RHCommerceDev/utils/constants";
import getCountryFromUrl from "utils/getCountryFromUrl";

const RHPromoBanner = () => {
  const prefix = useLocalization();
  const permissionCountry = getCountryFromUrl();

  const history = useHistory();

  const [{ rh_promo_banner, cookie_preferenes_banner_opened }, setCookie] =
    useCookies(["rh_promo_banner", "cookie_preferenes_banner_opened"]);

  const handleClose = React.useCallback(
    () => setCookie("rh_promo_banner", false),
    [setCookie]
  );

  const handleRedirect = React.useCallback(() => {
    history.push(`${prefix}/sale`);
  }, [history, prefix]);

  const isAlreadyOnSalePage = useRouteMatch(`${prefix}/sale`);
  const isNA = NA_COUNTRIES?.includes(permissionCountry ?? "US");
  const open =
    !isAlreadyOnSalePage && isNA && typeof rh_promo_banner === "undefined"
      ? true
      : yn(rh_promo_banner);

  if (cookie_preferenes_banner_opened) {
    return null;
  }

  return open ? (
    <div className="fixed inset-x-0 bottom-0 pb-6 sm:mx-8 md:mx-10  z-[9999]">
      <div
        className={classNames(
          "flex flex-col sm:flex-row gap-6 sm:gap-12 lg:gap-16  items-center justify-between w-full py-12 px-8 sm:py-6 sm:px-12 bg-black/80"
        )}
      >
        <p
          className={classNames(
            "text-white  text-lg font-primary-rhlight leading-[27px]  tracking-[0.27px] text-wrap"
          )}
        >
          JANUARY CLEARANCE EVENT. Save up to 60% on Outdoor, Living, Dining,
          Bedroom & More.
        </p>
        <button
          className="flex-shrink-0 p-0 h-12 text-center text-black bg-white text-[11px] font-light font-primary-rhlight uppercase leading-[13.20px] tracking-[0.44px] w-full sm:w-[137px]"
          onClick={handleRedirect}
        >
          SHOP THE SALE
        </button>
        <button
          className="absolute right-4 m-4 translate-x-full p-0 text-white top-0 sm:top-[unset]"
          onClick={handleClose}
        >
          <RHCloseIcon className={"!text-[16px]"} />
        </button>
      </div>
    </div>
  ) : null;
};

export default memoize(RHPromoBanner);
