import { useQuery } from "@apollo/client";
import { queryGetCart } from "graphql-client-cart-broker/queries/get-cart";
import { useCartIdForSession } from "@RHCommerceDev/page-checkout-v2/hooks/useCartIdForSession";
import useLocale from "hooks-use-locale/useLocale";
import { useContext } from "react";
import useSite from "./useSite";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { countryCurrencyMapper } from "resources/countries-config.json";
import { UserPreferencesContext } from "customProviders/UserPreferencesProvider";

export function useCart(isAemPage = false) {
  const { cartId } = useCartIdForSession();
  const siteId = useSite();
  const locale = useLocale();
  const { cart, setCart } = useContext(UserPreferencesContext);
  const currencyCode = countryCurrencyMapper?.[getCountryFromUrl() || "US"];
  const { loading, refetch } = useQuery<
    Pick<Query, "getCart">,
    QueryGetCartArgs
  >(queryGetCart, {
    variables: {
      cartId: cartId as string,
      locale,
      currencyCode,
      siteId
    },
    skip: !cartId || isAemPage,
    onCompleted: data => {
      setCart(data?.getCart);
    },
    onError: () => {},
    fetchPolicy: "no-cache"
  });

  return {
    cart,
    refetch,
    loading,
    setCart
  };
}
