import * as React from "react";
import * as ReactDOM from "react-dom";
import { setRef } from "../utils/useForkRef";
import useForkRef from "../utils/useForkRef";
import { PortalProps } from "./types";

/* Retrieves the container element for the portal. */
function getContainer(
  containerRef: React.RefObject<HTMLElement>,
  container?: React.ReactInstance | (() => React.ReactInstance | null) | null
): HTMLElement | null {
  if (containerRef.current) {
    return containerRef.current;
  }
  container = typeof container === "function" ? container() : container;
  return container as HTMLElement | null;
}

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

/**
 * Portals provide a first-class way to render children into a DOM node
 * that exists outside the DOM hierarchy of the parent component.
 */

const PortalRenderFunction: React.ForwardRefRenderFunction<
  HTMLElement,
  PortalProps
> = (props, ref) => {
  const { children, container, disablePortal = false, onRendered } = props;

  const [mountNode, setMountNode] = React.useState<HTMLElement | null>(null);
  const containerRef = React.useRef<HTMLElement>(null);

  // Combine the child's ref and the forwarded ref into a single ref callback
  const handleRef = useForkRef(
    React.isValidElement(children) ? (children as any).ref : null,
    ref
  );

  // Set the mount node for the portal when the container or disablePortal changes
  useEnhancedEffect(() => {
    if (!disablePortal) {
      setMountNode(getContainer(containerRef, container) || document.body);
    }
  }, [container, disablePortal]);

  // Set the ref to the mount node when the mount node is available and the portal is not disabled
  useEnhancedEffect(() => {
    if (mountNode && !disablePortal) {
      setRef(ref, mountNode);
      return () => {
        setRef(ref, null);
      };
    }
    return undefined;
  }, [ref, mountNode, disablePortal]);

  // Call the onRendered callback when the portal's children have been mounted into the container
  useEnhancedEffect(() => {
    if (onRendered && (mountNode || disablePortal)) {
      onRendered();
    }
  }, [onRendered, mountNode, disablePortal]);

  // Render children directly if the portal is disabled, cloning the element to add a ref if necessary
  if (disablePortal) {
    if (React.isValidElement<{ ref?: React.Ref<any> }>(children)) {
      return React.cloneElement(children, { ref: handleRef });
    }
    return <>{children}</>;
  }

  return mountNode ? ReactDOM.createPortal(children, mountNode) : null;
};

export const Portal = React.forwardRef(PortalRenderFunction);
