import React from "react";

export const RHDropdownIconV2 = props => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        {...props}
      >
        <path
          d="M12.1699 6.75L8.16992 10.75L4.16992 6.75"
          stroke="black"
          stroke-width={props?.strokeWidth}
        />
      </svg>
    </>
  );
};

export default RHDropdownIconV2;
