import { EVENTS, TRIGGERS } from "./config";
import { buildPayload } from "./build";
import {
  DeepReadonly,
  AnalyticsEventDetail,
  ObjectMapper,
  TriggerByEventConfig
} from "./types";
import { addHistory, addLocation } from "./state";
import { emitAnalyticsEvent } from "./emit";
import {
  addCatalogToLocalStorageForDesktop,
  addCatalogToLocalStorage,
  handleBackNavForCatalog,
  addTypeaheadSearchTerm,
  clearLocalStorage,
  addCatalogToMemoryForDesktop
} from "./custom-analytics/storage";
import { processEnvServer } from "hooks/useSsrHooks";
import { setStorageValue } from "hooks/useCookiesWithPermission";
import { memoryStorage } from "./storage";
import { sleep } from "utils/sleep";

export const subscriberMapper: ObjectMapper = {
  pushHistory: (detail: AnalyticsEventDetail) => {
    addHistory(detail.data.location?.href, detail.data.local?.action);
    addLocation(detail.data.location!);
  },
  settingLocalStorage: (detail: AnalyticsEventDetail) => {
    if (detail.data.local?.category) {
      addCatalogToLocalStorageForDesktop(detail);
    } else if (detail.data.local?.item) {
      addCatalogToLocalStorage(detail);
    } else if (detail.data.local?.type) {
      handleBackNavForCatalog(detail);
    } else if (detail.data.local?.searchTerm) {
      addTypeaheadSearchTerm(detail);
    } else if (detail.data.local?.remove) {
      if (detail.data.local?.remove === "all") {
        clearLocalStorage();
      }
      if (!processEnvServer) {
        localStorage.removeItem(detail.data.local?.remove);
      }
    } else if (!processEnvServer && detail.data.local?.set) {
      const eleName = detail.data.local?.set?.name;
      const value = detail.data.local?.set?.value;
      setStorageValue({ storageKey: eleName, value: value });
    } else if (!processEnvServer && detail.data.local?.sale) {
      setStorageValue({
        storageKey: "analytics-catalog",
        value: JSON.stringify([{ name: "SALE" }])
      });
    } else if (!processEnvServer && detail.data.local?.saleElement) {
      const saleElement = detail.data.local?.saleElement;
      const data = [
        {
          id: saleElement.id,
          name: saleElement.displayName
        }
      ];
      setStorageValue({
        storageKey: "analytics-catalog",
        value: JSON.stringify(data)
      });
    }
  },
  settingMemoryStorage: (detail: AnalyticsEventDetail) => {
    addCatalogToMemoryForDesktop(detail.data.local);
  }
};

export const notifySubscribers = (
  detail: AnalyticsEventDetail
): AnalyticsEventDetail => {
  const eventDef =
    EVENTS[Object.keys(EVENTS).find(e => EVENTS[e].INT_TYPE === detail.type)!];
  eventDef?.SUBSCRIBERS.forEach(s => subscriberMapper[s](detail));
  return detail;
};
interface Payload {
  event: string | undefined;
  ecommerce?: any;
  value?: any;
  currency?: any;
  form_name?: string;
  form_start?: boolean;
  form_submit_status?: boolean;
  user?: GTMUserField;
  membership_id?: any;
  [key: string]: any;
}
export interface GTMUserField {
  type: string;
  id: string;
  // postal_code: string | null;
  is_member?: boolean;
  userType?: string;
}

interface EcommerceData {
  items: {
    item_id: string;
    item_name: string;
    item_variant: string;
    quantity: number;
    price: number;
    item_category: string;
    item_list_name: string | null;
    primaryProduct: string | boolean;
  }[];
  value: number;
  currency: string;
  item_list_name: string;
}

const extractEcommerceData = (payload: any): EcommerceData => {
  const {
    items,
    currency,
    value,
    item_list_name,
    transaction_id,
    tax,
    shipping,
    ...rest
  } = payload;
  return {
    ...(items && { items }),
    ...(currency && { currency }),
    ...(value >= 0 && { value }),
    ...(item_list_name && { item_list_name }),
    ...(transaction_id && { transaction_id }),
    ...(tax >= 0 && { tax }),
    ...(shipping >= 0 && { shipping })
  };
};

const removeEcommerceData = (payload: any, eventType: string): any => {
  const {
    items,
    value,
    currency,
    item_list_name,
    transaction_id,
    tax,
    shipping,
    ...rest
  } = payload;

  let result = { ...rest };

  if (eventType?.includes("ga4")) {
    const { page, transaction, ...rest } = result;
    result = rest;
  }

  return result;
};

const addFinalListeners = (DOMNode: HTMLElement) =>
  Object.keys(EVENTS)
    .map(k => EVENTS[k].INT_TYPE)
    .forEach((eventType: string) =>
      // @ts-ignore
      DOMNode.addEventListener(eventType, async (event: CustomEvent) => {
        event.stopPropagation();
        const eventDef =
          EVENTS[
            Object.keys(EVENTS).find(
              e => EVENTS[e].INT_TYPE === event.detail.type
            )!
          ];
        if (!eventDef?.EXT_TYPE) {
          notifySubscribers(event.detail);
        } else {
          const writeData = (data: object) => {
            var newDiv = document.createElement("div");
            newDiv.id = "outputSpan2";
            newDiv.innerHTML = `${JSON.stringify(data)}`;
            document.body.appendChild(newDiv);
          };
          let build = await buildPayload(notifySubscribers(event.detail));
          let payloadData = build.payload;
          let payload: Payload = {
            event: eventDef?.GTM,
            ecommerce: build.payload
          };

          if (!processEnvServer) {
            if (eventDef?.EXT_TYPE === "analytics-form-submit") {
              payload.ecommerce = {};
              payload.user = build.payload?.user;
              payload.membership_id = build.payload?.membership_id;
              payload.form_name = build.payload?.form_name;
              if (build.payload?.form_start) {
                payload.form_start = build.payload?.form_start;
              } else if (build.payload?.form_submit_status) {
                payload.form_submit_status = build.payload?.form_submit_status;
              }
            } else if (eventDef?.EXT_TYPE === "analytics-view-page") {
              setStorageValue({
                storageKey: "payload",
                value: JSON.stringify(payload.ecommerce)
              });
            }
            if (eventDef?.INT_TYPE.includes("ga4-tracking-int-")) {
              const ecommerceData = extractEcommerceData(payloadData);
              const nonValueData = removeEcommerceData(
                payloadData,
                eventDef?.INT_TYPE
              );
              payload.ecommerce = ecommerceData;
              if (eventDef?.EXT_TYPE === "analytics-add-to-wishlist") {
                payload.ecommerce.value = build.data.local.value;
              } else if (eventDef?.EXT_TYPE === "analytics-view-item-list") {
                payload.item_list_name =
                  build.data.local.item_list_name_view_item;
              } else if (eventDef?.EXT_TYPE === "analytics-add-payment-info") {
                payload.ecommerce.value = build.data.local.value;
                payload.ecommerce.payment_type =
                  build.data.local.selectedPayment;
              } else if (
                eventDef?.EXT_TYPE === "analytics-add-to-cart" ||
                eventDef?.EXT_TYPE === "analytics-view_cart"
              ) {
                payload.ecommerce.value = ecommerceData?.items?.reduce(
                  (acc, cur) => acc + +cur.price,
                  0
                );
              }
              payload = { ...payload, ...nonValueData };
            }
          }
          const dataLayer = window?.["dataLayer"];
          if (dataLayer) {
            dataLayer.push({
              ...payload
            });
          } else {
            await sleep(300);
            document.body.dispatchEvent(
              new CustomEvent(eventDef?.EXT_TYPE, { detail: payload })
            );
          }
        }
      })
    );
let touchstartX = 0;
let touchendX = 0;
let direction = "";

function checkDirection() {
  if (touchendX < touchstartX) return "right";
  if (touchendX > touchstartX) return "left";
  return "";
}
const addTriggersListeners = (root: HTMLElement) => {
  if (TRIGGERS.BY_DOM_EVENT) {
    TRIGGERS.BY_DOM_EVENT.forEach((c: DeepReadonly<TriggerByEventConfig>) =>
      c.HOSTS.forEach((host: string) => {
        try {
          const h = document.querySelector(host)!;
          // @ts-ignore
          h.addEventListener(c.EVENT, (e: ClickEvent) => {
            const foundDelegate = c.DELEGATES?.filter(d =>
              e.target?.matches(d)
            )[0];
            const previousURL =
              !processEnvServer &&
              localStorage.getItem("analytics-previous-url");
            const url = previousURL && JSON.parse(previousURL);
            const swipe = foundDelegate?.includes("rh-carousels");
            let currentElement = (e.type === "touchend" || swipe) && e.target;
            const isPGPage = url?.currentURL?.includes("products.jsp");
            const arrowDirection = e.target?.getAttribute("data-analytics-id");
            if (currentElement && !isPGPage) {
              if (e.type === "touchstart") {
                touchstartX = e?.changedTouches?.[0]?.screenX;
              }
              if (e.type === "touchend") {
                touchendX = e.changedTouches[0].screenX;
                direction = checkDirection();
              } else if (arrowDirection) {
                direction = arrowDirection === "previous" ? "left" : "right";
              }
              while (
                currentElement &&
                !currentElement?.classList?.contains("slick-initialized")
              ) {
                currentElement = currentElement?.parentElement;
              }

              if (currentElement?.children) {
                for (const child of currentElement?.children) {
                  if (child?.classList?.contains("slick-list")) {
                    currentElement = child?.querySelector(".slick-current");
                    break;
                  }
                }
              }
              const currentElem = currentElement?.querySelector(
                ".MuiTypography-root"
              );
              if (!currentElem && e.type !== "touchstart") {
                currentElement = currentElement?.querySelector(".MuiGrid-root");
                if (currentElement) {
                  return document.body.dispatchEvent(
                    new CustomEvent("pre-pdp-click", {
                      detail: {
                        item: {
                          action:
                            direction === "left"
                              ? "Alt Caurosel - Prev Arrow"
                              : "Alt Caurosel - Next Arrow"
                        }
                      }
                    })
                  );
                }
              } else {
                currentElement = currentElem;
              }
            }
            const navigationDropdown = e.target
              ?.closest("a")
              ?.getAttribute("id");
            if (
              e.type !== "touchstart" &&
              (!c.DELEGATES || foundDelegate || currentElement) &&
              navigationDropdown !== "nav-logo-img"
            ) {
              const closestEle =
                currentElement ||
                e.target.closest("a") ||
                e.target.closest("button");

              const heroArrowButton =
                isPGPage &&
                foundDelegate ===
                  ".aem-container [data-analytics-id='rh-carousels'] button *";
              const collectionArrowIcon =
                foundDelegate ===
                  ".aem-container [data-analytics-id='previousIcon']" ||
                foundDelegate ===
                  ".aem-container [data-analytics-id='nextIcon']";
              const collectionBanner =
                foundDelegate ===
                  ".aem-container [data-analytics-id='collection-text'] *" ||
                foundDelegate ===
                  ".aem-container [data-analytics-id='collection-carousel'] *";
              if (collectionArrowIcon || collectionBanner || heroArrowButton) {
                let action = "";
                const closestElement =
                  e.target.closest("svg") ||
                  e.target.closest("img") ||
                  e.target.closest("p");
                if (collectionArrowIcon || heroArrowButton) {
                  action =
                    closestElement?.getAttribute("data-analytics-id") ===
                      "previousIcon" || arrowDirection === "previous"
                      ? `Previous click - ${
                          heroArrowButton ? "Hero" : "Related"
                        } Carousel`
                      : `Next click - ${
                          heroArrowButton ? "Hero" : "Related"
                        } Carousel`;
                } else {
                  action = `Click ${
                    closestElement?.innerText ||
                    closestElement?.getAttribute("data-analytics-id")
                  }`;
                }
                memoryStorage.setItem("cauroselCollection", true);
                return document.body.dispatchEvent(
                  new CustomEvent("pg-click", {
                    detail: {
                      item: { action }
                    }
                  })
                );
              }
              emitAnalyticsEvent(
                // @ts-ignore
                h,
                swipe ? "tracking-int-view-banner" : c.EMIT,
                {
                  swipe: currentElement ? "View" : "",
                  direction: direction ? direction : "",
                  target: e.target,
                  delegated: foundDelegate,
                  previousHistory: true,
                  closestAnchor:
                    foundDelegate === "ul li[data-worh-type='worh-icon'] *" ||
                    foundDelegate ===
                      "ul li[data-worh-type='rhr-uber-cat'] h4" ||
                    foundDelegate === "ul li[data-worh-type='rhr-sub-cat'] h4"
                      ? e.target.closest("li")
                      : closestEle
                }
              );
            }
          });
        } catch (error) {}
      })
    );
  }
  if (TRIGGERS.BY_GLOBAL_EVENT) {
    TRIGGERS.BY_GLOBAL_EVENT.forEach((c: DeepReadonly<TriggerByEventConfig>) =>
      c.HOSTS.forEach((host: string) => {
        try {
          // @ts-ignore
          const h =
            host === "body"
              ? document.body
              : host === "window"
              ? window
              : window[host as any];
          // @ts-ignore
          h.addEventListener(c.EVENT, (e: Event) =>
            emitAnalyticsEvent(root, c.EMIT, { [c.EVENT]: e })
          );
        } catch (error) {}
      })
    );
  }
};

export const listenAnalyticsEvents = (DOMNode: HTMLElement) => {
  addFinalListeners(DOMNode);
  addTriggersListeners(DOMNode);
};
