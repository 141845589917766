import { useState, useCallback } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import { useDeleteSessionMutation } from "@RHCommerceDev/hooks-mutations";
import yn from "yn";
import useStytch from "@RHCommerceDev/hooks/useStytch";

export const useAuth = () => {
  const env = useEnv();
  const FEATURE_STYTCH = yn(env?.FEATURE_STYTCH);

  // move loading to useSession after remove fetch request in signIn
  const [loading, setLoading] = useState(false);
  const { language } = useUserPreferences();
  const [signedOut, setSignedOut] = useState(false);
  const [error, setError] = useState(false);
  const [deleteSession] = useDeleteSessionMutation();
  const { setStorageValueWrapper } = useCookiesWithPermission();

  let locale = language?.split("-")?.[0] || "en";

  const { keycloak } = useKeycloak();

  const stytch = useStytch();

  const {
    REACT_APP_KEYCLOAK_ORIGIN,
    REACT_APP_KEYCLOAK_PATH,
    REACT_APP_KEYCLOAK_REALM,
    REACT_APP_KEYCLOAK_CLIENTID
  } = useEnv();

  const signOut = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      // -- TODO: Remove fetch after SessionProvider is fully released
      if (FEATURE_STYTCH) {
        await stytch?.session?.revoke();
        await deleteSession();
        setSignedOut(true);
      } else {
        const response = await fetch(
          `${REACT_APP_KEYCLOAK_ORIGIN}${REACT_APP_KEYCLOAK_PATH}/realms/${REACT_APP_KEYCLOAK_REALM}/rhlogout`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${keycloak?.token}`,
              Accept: "application/json"
            },
            body: null
          }
        );

        if (!response.ok) throw new Error();
      }

      await deleteSession();
      setSignedOut(true);
      localStorage.removeItem("isLoggedin");
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);

      if (!processEnvServer && !FEATURE_STYTCH) {
        const url = new URL(window?.location?.href);

        if (url?.pathname?.includes("shopping_cart.jsp")) {
          window.location.href = url.origin;
        }
      }
    }
  }, []);

  const getRedirectUri = useCallback((path?: string) => {
    if (!path) {
      return undefined;
    }

    const url = new URL(window.location.href);
    url.pathname = path;
    return url.href;
  }, []);

  const signIn = useCallback(
    async (path?: string) => {
      if (!processEnvServer) {
        setStorageValueWrapper({
          storageKey: "REACT_APP_KEYCLOAK_CLIENTID",
          value: REACT_APP_KEYCLOAK_CLIENTID
        });
      }

      const redirectUri = getRedirectUri(path);
      keycloak.clientId = REACT_APP_KEYCLOAK_CLIENTID;
      keycloak.login({ redirectUri, locale });
      setSignedOut(false);
    },
    [setStorageValueWrapper]
  );

  return {
    loading,
    error,
    signOut,
    signIn,
    signedOut
  };
};
