// copied from https://markus.oberlehner.net/blog/simple-solution-to-prevent-body-scrolling-on-ios/

const bodyEl = document?.querySelector("body");
let scrollPosition = 0;

export const enable = () => {
  if (bodyEl) {
    scrollPosition = window?.pageYOffset ?? 0;
    bodyEl.style.overflowY = "hidden";
    bodyEl.style.position = "fixed";
    bodyEl.style.top = `-${scrollPosition}px`;
    bodyEl.style.width = "100%";
  }
};

export const disable = () => {
  if (bodyEl) {
    bodyEl.style.overflowY = "auto";
    bodyEl?.style?.removeProperty("position");
    bodyEl?.style?.removeProperty("top");
    bodyEl?.style?.removeProperty("width");
    window?.scrollTo(0, scrollPosition);
  }
};

export const setInitialDocumentOverFlow = () => {
  if (document?.documentElement) {
    document.documentElement.style.overflow = "visible";
  }
};
