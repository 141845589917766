import gql from "graphql-tag";
import { Gallery, BaseGallery } from "graphql-client/fragments/gallery";

export const queryGallery = gql`
  query Gallery($storeId: Int!, $locale: String) {
    gallery(storeId: $storeId, locale: $locale) {
      ...GalleryInfo
    }
  }
  ${Gallery}
`;

export const queryBaseGalleries = gql`
  query Galleries {
    galleries {
      ...BaseGalleryInfo
    }
  }
  ${BaseGallery}
`;

export const queryGalleries = gql`
  query Galleries($countryCode: String) {
    galleries(countryCode: $countryCode) {
      ...GalleryInfo
    }
  }
  ${Gallery}
`;

export const queryGalleriesByLocation = gql`
  query GalleriesByLocation(
    $lat: String!
    $lng: String!
    $distance: Int! = 1000
  ) {
    galleriesByLocation(lat: $lat, lng: $lng, distance: $distance) {
      distance
      gallery {
        ...GalleryInfo
      }
    }
  }
  ${Gallery}
`;
