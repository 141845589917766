import analyticsLoader from "analytics/loader";
import React, {
  CSSProperties,
  FC,
  useEffect,
  useState,
  RefObject
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import useAppData from "@RHCommerceDev/hooks/useAppData";
import { useParams } from "@RHCommerceDev/hooks/useParams";
import { getCookie } from "@react-keycloak/ssr/lib/persistors/utils";
import { shouldReloadForATG } from "@RHCommerceDev/utils/shouldReloadForATG";
import { queryGlobalStylesheet } from "@RHCommerceDev/graphql-client/queries/global-stylesheet";
import Helmet from "react-helmet";
import { useResizeDetector } from "react-resize-detector";
import htmlToJson from "@RHCommerceDev/utils/htmlToJson";
import buildPath from "@RHCommerceDev/utils/buildPath";
import useBrand from "@RHCommerceDev/hooks-use-brand/useBrand";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import useDidMountEffect from "@RHCommerceDev/hooks/useDidMountEffect";
import useIsoRedirect from "@RHCommerceDev/hooks-use-isoredirect";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import useExternalLinks from "@RHCommerceDev/hooks/useExternalLinks";
import useGoogleTagManager from "@RHCommerceDev/hooks/useGoogleTagManager";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { useChunkLoadErrorRecovery } from "@RHCommerceDev/hooks/useChunkLoadErrorRecovery";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";
import { REQUIRED_PERMISSION_COUNTRIES } from "@RHCommerceDev/utils/constants";
import { sleep } from "@RHCommerceDev/utils/sleep";
import { useUserSessionAtomValue } from "@RHCommerceDev/hooks/atoms";
import { useKeycloak } from "@RHCommerceDev/utils/Keycloak/KeyCloak";
import {
  decrementTabCount,
  getTabDetails,
  incrementTabCount,
  setEventTriggered
} from "@RHCommerceDev/analytics/utils";

const pluginPromise = !processEnvServer
  ? import(/* webpackChunkName: "plugins" */ "plugins")
  : null;

export interface AppProps {
  rootStyle?: CSSProperties;
  children?: any;
}

async function handleRouteChange(
  location: any,
  action: string | null,
  appRef: React.RefObject<HTMLDivElement>,
  priorPathname: string,
  shouldTriggerEvent: Maybe<boolean>
) {
  if (
    !priorPathname.includes("/products.jsp") ||
    (priorPathname.includes("/products.jsp") &&
      document.body?.dataset?.pagePath !== priorPathname)
  ) {
    window?.scrollTo(0, 0);
  }
  if (appRef && appRef.current) {
    /**
      takes of anything after the final slash in location.pathname
      i.e. if pathname === /catalogs/products.jsp
      it returns ["catalogs", "products.jsp"]
    **/
    /**
      for some pages that has "/" at end like /rooms.jsp/
      SeparatePathStringToFindCurrentPath returns empty string as last element
      like ["", "rooms.jsp",""].
      so removing / if it was last charecter in path name
    **/
    const separatePathStringToFindCurrentPath: string[] =
      location.pathname.at(-1) === "/"
        ? location.pathname.slice(0, -1).split("/")
        : location.pathname.split("/");

    /**
     * Gets last item of array above
     * i.e. "products.jsp"
     **/
    const currentPageType: string = location.pathname.includes(
      "/checkout/payment.jsp"
    )
      ? "/checkout/payment.jsp"
      : separatePathStringToFindCurrentPath[
          separatePathStringToFindCurrentPath.length - 1
        ];

    const productPages = [
      "collections.jsp",
      "products.jsp",
      "rooms",
      "rooms.jsp",
      "product.jsp",
      "payment.jsp",
      "sale-products.jsp",
      "final-sale.jsp",
      "expired-order.jsp",
      "shopping_cart.jsp",
      "results.jsp",
      "/search/"
    ];
    const productPagePathname = productPages.some(page =>
      location?.pathname?.includes(page)
    );
    if (!processEnvServer) {
      const urlObj = new URL(window?.location?.href);
      const paymentPortalToken = urlObj.searchParams.get("paymentPortalToken");
      const token = urlObj.searchParams.get("token");
      if (
        shouldTriggerEvent &&
        ((window?.location?.hostname?.includes("rhguesthouse") &&
          currentPageType === "rooms") ||
          (location?.pathname?.includes("/checkout/payment.jsp") &&
            !(paymentPortalToken || token)) ||
          !productPagePathname)
      ) {
        await sleep(500);
        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.GA4_VIEW_PAGE.INT_TYPE,
            {
              action
            }
          )
        );
      }
    }
  }
}

const ssrPaths = [
  "/",
  "/catalog/category/collections.jsp",
  "/catalog/category/products.jsp",
  "/catalog/product/product.jsp",
  "/search/results.jsp",
  "/search/",
  "/content/rh/us/en/membership.html",
  "/test-route"
];

export const App: FC<AppProps> = ({ children, rootStyle }) => {
  const brand = useBrand();
  const cookies = useIsoCookies(["PF_EXP"]);
  const { keycloak } = useKeycloak();
  const {
    cookiePreferences,
    rhUser,
    loading: isLoadingUserSession
  } = useUserSessionAtomValue();
  const { app, setApp } = useAppData();
  const params = useParams({
    sale: "",
    type: "",
    cm_vc: "",
    catalog: null
  });
  const location = useLocation();
  const isSsrPath =
    ssrPaths.includes(location.pathname) || !location.pathname.endsWith(".jsp");
  const [priorPathname, setPriorPathname] = useState(location.pathname);
  const { height, ref: appRef } = useResizeDetector();
  const history = useHistory();
  const userCatalogCookie = getCookie("userCatalog", cookies);
  const hasUserCatalogCookie = !!userCatalogCookie;
  const isDefaultCatalogCookie = userCatalogCookie === "default";
  const isMissingCatalogUrlParam = !!!params.catalog;
  const [stage, setStage] = useState("init");

  const { setCookieWrapper } = useCookiesWithPermission();
  const isGTMLoad = memoryStorage.getItem("isGTMLoad");

  const isGuestHouseRoute = window?.location?.hostname?.includes("guesthouse");

  /**
   * loading third party scripts
   */
  useExternalLinks();
  const { triggerEvent } = useGoogleTagManager();

  const {
    data: { contentFragment } = {} as Query,
    loading: globalStylesLoading
  } = useQuery(queryGlobalStylesheet);

  useChunkLoadErrorRecovery();

  //----------------------------------------
  // TODO: These changes can be removed once all the pages have been migrated from the old experience.

  // Hack to smooth the transition between the old and the new experience
  // as part of KWEB-1020.
  // A best-effort is made to add the missing catalog URL parameter based on the
  // "usetCatalog" cookie.
  // This hack might not always work though.
  //----------------------------------------
  useEffect(() => {
    if (
      isMissingCatalogUrlParam &&
      hasUserCatalogCookie &&
      !isDefaultCatalogCookie
    ) {
      const isValidUrl = [
        "/catalog/category/collections.jsp",
        "/catalog/category/products.jsp",
        "/category/context.jsp"
      ].includes(location.pathname);

      if (isValidUrl) {
        const req = getReqContext();
        let url = "";
        if (processEnvServer) {
          url = req.protocol + "://" + req.get("host") + req.originalUrl;
        }
        history.replace(
          buildPath(processEnvServer ? url : window.location.href, {
            catalog: userCatalogCookie
          })
        );
      }
    }
    // TODO: Fix eslint error and remove this comment block
    // React Hook useEffect has missing dependencies: 'history'
    // and 'location.pathname'. Either include them or remove the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMissingCatalogUrlParam,
    userCatalogCookie,
    hasUserCatalogCookie,
    isDefaultCatalogCookie
  ]);
  //----------------------------------------

  useEffect(() => {
    if (
      app?.saleContextFilter !== undefined ||
      location.pathname.indexOf(".jsp") === -1
    )
      return;
    if (
      params.type === "finalSale" ||
      location.pathname === "/catalog/sale/final-sale.jsp"
    ) {
      setApp({
        ...app,
        saleContextFilter: "finalSale"
      });
    } else if (params.sale === "true" || params.cm_vc === "sale") {
      setApp({
        ...app,
        saleContextFilter: "sale"
      });
    } else {
      setApp({
        ...app,
        saleContextFilter: null
      });
    }
  }, [params.sale, params.cm_vc, params.type, location.pathname, setApp, app]);

  useEffect(() => {
    if (app?.message) {
      setApp({
        ...app,
        message: null
      });
    }
  }, [app, setApp]);

  if (!processEnvServer) {
    useEffect(() => {
      if (height) {
        window.parent?.postMessage(
          {
            event: "child_resize",
            height
          },
          "*"
        );
      }
    }, [height]);
  }

  useEffect(() => {
    if (
      !processEnvServer &&
      !getTabDetails()?.isTrigger &&
      rhUser?.akamaiCountryCode
    ) {
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          a.EVENTS.NEW_TAB_OPENED.INT_TYPE,
          {
            count: getTabDetails().tabCount || 1
          }
        )
      );
      setEventTriggered();
    }
  }, [rhUser?.akamaiCountryCode]);

  useEffect(() => {
    if (!processEnvServer) {
      incrementTabCount();
      const handleTabClose = () => {
        decrementTabCount();
      };
      window.addEventListener("beforeunload", handleTabClose);

      return () => {
        window.removeEventListener("beforeunload", handleTabClose);
        decrementTabCount();
      };
    }
  }, []);

  if (!processEnvServer) {
    useEffect(() => {
      // This function call is for the first page load of the app (action param should probably be null here since there was no routing action on initial page load)
      setTimeout(() => {
        const isGTMLoaded =
          isGTMLoad || Boolean(sessionStorage.getItem("isGTMLoaded"));
        const isLoggedin =
          rhUser?.userType?.toUpperCase() !== "ANONYMOUS" &&
          rhUser?.userType?.toUpperCase() !== "GUEST";
        let isSession;
        if (
          !isLoadingUserSession &&
          !!triggerEvent &&
          ((!keycloak?.authenticated && !isLoggedin) ||
            keycloak?.authenticated ||
            isLoggedin)
        ) {
          isSession = true;
        } else {
          isSession = false;
        }
        const shouldTriggerEvent = Boolean(
          rhUser?.akamaiCountryCode && isGTMLoaded && isSession
        );
        handleRouteChange(
          location,
          null,
          appRef as RefObject<HTMLDivElement>,
          priorPathname,
          shouldTriggerEvent
        );
      }, 500);
    }, [
      rhUser?.akamaiCountryCode,
      isGTMLoad,
      triggerEvent,
      isLoadingUserSession
    ]);

    useEffect(() => {
      history.listen((location, action) => {
        const isSavedCookiePreference = memoryStorage.getItem(
          "savedCookiePreference"
        );
        const saveCookiePermission = memoryStorage.getItem(
          "saveCookiePermission"
        );
        const isGTMLoaded =
          Boolean(sessionStorage.getItem("isGTMLoaded")) ||
          (!isSavedCookiePreference &&
            rhUser?.akamaiCountryCode &&
            (REQUIRED_PERMISSION_COUNTRIES?.includes(rhUser?.akamaiCountryCode)
              ? rhUser?.akamaiCountryCode
              : cookiePreferences?.cookieRules)) ||
          "";
        const countryCode = !isSavedCookiePreference
          ? rhUser?.akamaiCountryCode
          : null;
        const isConfirmationPage =
          location.pathname?.includes("confirmation.jsp");
        const shouldTriggerEvent = Boolean(
          !isConfirmationPage &&
            ((isGTMLoaded && countryCode) ||
              (rhUser?.akamaiCountryCode &&
                !!triggerEvent &&
                !saveCookiePermission))
        );
        // This function call is for when route changes
        handleRouteChange(
          location,
          action,
          appRef as RefObject<HTMLDivElement>,
          priorPathname,
          shouldTriggerEvent
        );
        // add data-page-path attribute to body
        document.body.dataset.pagePath = location.pathname;
        setPriorPathname(location.pathname);
        if (rhUser?.akamaiCountryCode) {
          memoryStorage.removeItem("savedCookiePreference");
        }
      });
      // TODO: Fix eslint error and remove this comment
      // React Hook useEffect has missing dependencies: 'history', 'location', and 'priorPathname'.
      // Either include them or remove the dependency array  react-hooks/exhaustive-deps
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rhUser?.akamaiCountryCode]);

    useDidMountEffect(() => {
      if (stage === "afterInit") {
        pluginPromise?.then(({ init }) => init(undefined, undefined, stage));
      }
    }, [stage]);

    useDidMountEffect(() => {
      if (stage === "init" && !globalStylesLoading) {
        setStage("afterInit");
      }
    }, [stage, globalStylesLoading]);

    if (
      shouldReloadForATG(cookies, {
        pathname: location.pathname,
        search: location.search,
        hash: location.hash,
        brand: brand
      })
    ) {
      const req = getReqContext();
      let url = "";
      if (processEnvServer) {
        url = req.protocol + "://" + req.get("host") + req.originalUrl; // Generating the url on server side from req
      } else {
        url = "";
      }
      useIsoRedirect(url, "windowReload"); // Forces the page to reload
      return null; // return null so the routes below this do not change.  We are done with the react app at this point.
    }
  }

  useEffect(() => {
    if (!processEnvServer) {
      const { pathname: path, search } = location;
      if (search && search.includes("utm_medium")) {
        const queryParams = new URLSearchParams(search);
        const medium = queryParams.get("utm_medium");
        if (medium === "email") {
          const subscriberID = queryParams.get("sfmc_sub");
          const jobID = queryParams.get("j");
          const listID = queryParams.get("l");
          const originalLinkID = queryParams.get("u");
          const memberID = queryParams.get("mid");
          const batchID = queryParams.get("jb");
          setCookieWrapper("Medium", medium, { path });
          setCookieWrapper("SubscriberID", subscriberID, { path });
          setCookieWrapper("JobID", jobID, { path });
          setCookieWrapper("ListID", listID, { path });
          setCookieWrapper("OriginalLinkID", originalLinkID, { path });
          setCookieWrapper("MemberID", memberID, { path });
          setCookieWrapper("BatchID", batchID, { path });
        }
      }
    }
  }, [location.pathname, location.search]);
  /** Recovery from  "ChunkLoadError: Loading chunk x failed" */
  useEffect(() => {
    if (!processEnvServer) {
      const listener = (error: ErrorEvent): void => {
        if (/Loading chunk [\d]+ failed/.test(error?.message)) {
          window.location.reload();
        }
      };
      window.addEventListener("error", listener);
      return () => window.removeEventListener("error", listener);
    }
  }, []);

  return (
    <>
      <Helmet
        bodyAttributes={{
          "data-brand": brand,
          "data-page-path": window.location.pathname,
          "data-user-type": rhUser?.userType || ""
        }}
      >
        <style type="text/css">
          {!!processEnvServer
            ? contentFragment?.text
                ?.replace("<style>", "")
                .replace("</style>", "")
            : htmlToJson(contentFragment?.text ?? "").textContent}
        </style>
        {isGuestHouseRoute ? (
          <style type="text/css">{`body { background-color: black; }`}</style>
        ) : null}
        <script src={process.env.REACT_ADOBE_URL} async></script>
      </Helmet>

      <div
        ref={appRef}
        //The minimum height is causing a bounce-back with the iframe in the AEM editor
        style={rootStyle}
        //-------------------------------------------
        // The correct one is the one being used on <Helmet ...> above, however, we cannot remove this
        // otherwise, it would break the global stylesheet setup made on BCC by the Eugene.
        //-------------------------------------------
        data-page-path={window.location.pathname}
        //-------------------------------------------
      >
        {(isSsrPath && children) || children}
      </div>
    </>
  );
};

App.defaultProps = {
  rootStyle: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden"
  }
};

export const AppDefaultProps = App.defaultProps;

export default App;
