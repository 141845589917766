import React, { FC } from "react";
import RHHamburger from "@RHCommerceDev/icon-hamburger";
import classNames from "classnames";
import { usePageContent } from "customProviders/LocationProvider";
import useButtonStyles from "hooks/useButtonStyles";
import {
  CLOSE_HAMBURGER_MENU,
  OPEN_HAMBURGER_MENU
} from "resources/hamburger-nav.json";

export interface AnimatedHamburgerIconProps {
  isOpen?: boolean;
  color?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  style?: React.CSSProperties;
}

export const AnimatedHamburgerIcon: FC<AnimatedHamburgerIconProps> = ({
  isOpen = false,
  color,
  onClick,
  style = {}
}) => {
  const { pageContent } = usePageContent();
  const buttonStyles = useButtonStyles({
    keys: ["unstyledBtn"]
  });

  const CLOSE_HAMBURGER_LABEL =
    pageContent?.hamburgerNav?.CLOSE_HAMBURGER_MENU ?? CLOSE_HAMBURGER_MENU;
  const OPEN_HAMBURGER_LABEL =
    pageContent?.hamburgerNav?.OPEN_HAMBURGER_MENU ?? OPEN_HAMBURGER_MENU;

  return (
    <div style={{ padding: "17px 16px 17px 10px", ...style }}>
      <button
        id="hamburgerIcon"
        data-testid={"hamburgerIcon"}
        className={classNames(
          "h-4 w-4 xsm:h-6 xsm:w-6 cursor-pointer",
          buttonStyles.unstyledBtn,
          isOpen ? "open !mt-0" : "menuhamburger"
        )}
        onClick={onClick}
        aria-label={isOpen ? CLOSE_HAMBURGER_LABEL : OPEN_HAMBURGER_LABEL}
        aria-expanded={isOpen}
      >
        {isOpen ? (
          <div className="hamburger-lines">
            <span style={{ backgroundColor: color }} />
            <span style={{ backgroundColor: color }} />
            <span style={{ backgroundColor: color }} />
          </div>
        ) : (
          <RHHamburger color={color} />
        )}
      </button>
    </div>
  );
};

export default AnimatedHamburgerIcon;
