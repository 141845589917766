import { ObjectMapper } from "./types";
import { EVENTS } from "./config";
import { AnalyticsEventDetail, DeepReadonly, SpecConfig } from "./types";

export const eventMapper: ObjectMapper = {
  [EVENTS.GA4_VIEW_PAGE.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.GA4_VIEW_PAGE.SPEC,
  [EVENTS.CONTENT_LOADED_HOME.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.CONTENT_LOADED_HOME.SPEC,
  [EVENTS.GA4_VIEW_PAGE_SEARCH.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.GA4_VIEW_PAGE_SEARCH.SPEC,
  [EVENTS.GA4_VIEW_PAGE_PRODUCT.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.GA4_VIEW_PAGE_PRODUCT.SPEC,
  // [EVENTS.VIEW_PAGE_FAIL.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
  //   EVENTS.VIEW_PAGE_FAIL.SPEC,
  [EVENTS.GA4_ADD_TO_CART.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.GA4_ADD_TO_CART.SPEC,
  [EVENTS.GA4_WISHLIST.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.GA4_WISHLIST.SPEC,
  [EVENTS.GA4_ADD_SHIPPING_INFO.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.GA4_ADD_SHIPPING_INFO.SPEC,
  [EVENTS.GA4_ADD_PAYMENT_INFO.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.GA4_ADD_PAYMENT_INFO.SPEC,
  [EVENTS.CTA_CLICK.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.CTA_CLICK.SPEC,
  [EVENTS.PRE_PDP_CLICK.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.PRE_PDP_CLICK.SPEC,
  [EVENTS.COUNTRY_SWITCHER_POPUP.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.COUNTRY_SWITCHER_POPUP.SPEC,
  [EVENTS.REQ_CONSULTATION.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.REQ_CONSULTATION.SPEC,
  [EVENTS.REQ_CONSULTATION_CLICK.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.REQ_CONSULTATION_CLICK.SPEC,
  [EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.ADD_TO_LOCALSTORAGE.SPEC,
  [EVENTS.ADD_TO_MEMORY.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.ADD_TO_MEMORY.SPEC,
  [EVENTS.FUSION_SEARCH_SIGNAL.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.FUSION_SEARCH_SIGNAL.SPEC,
  [EVENTS.SWATCH_PLACE_ORDER.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.SWATCH_PLACE_ORDER.SPEC,
  [EVENTS.TRADE_REQUEST.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.TRADE_REQUEST.SPEC,
  [EVENTS.TRADE_REQUEST_CLICK.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.TRADE_REQUEST_CLICK.SPEC,
  [EVENTS.SUBMIT_ONLINE_RETURN.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.SUBMIT_ONLINE_RETURN.SPEC,
  [EVENTS.REMOVE_ONLINE_RETURN_ITEM.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.REMOVE_ONLINE_RETURN_ITEM.SPEC,
  [EVENTS.GA4_PURCHASE.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.GA4_PURCHASE.SPEC,
  [EVENTS.VIDEO_PLAY.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.VIDEO_PLAY.SPEC,
  [EVENTS.VIDEO_PAUSE.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.VIDEO_PAUSE.SPEC,
  [EVENTS.VIDEO_PROGRESS.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.VIDEO_PROGRESS.SPEC,
  [EVENTS.VIDEO_JUMP.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.VIDEO_JUMP.SPEC,
  [EVENTS.EMAIL_SUBSCRIPTION.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.EMAIL_SUBSCRIPTION.SPEC,
  [EVENTS.GA4_FORM_SUBMIT.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.GA4_FORM_SUBMIT.SPEC,
  [EVENTS.ADD_ON_CHECKBOX.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.ADD_ON_CHECKBOX.SPEC,
  [EVENTS.BEGIN_CHECKOUT.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.BEGIN_CHECKOUT.SPEC,
  [EVENTS.VIEW_BANNER.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.VIEW_BANNER.SPEC,
  [EVENTS.VIEW_ITEM_LIST.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.VIEW_ITEM_LIST.SPEC,
  [EVENTS.SEARCH.INT_TYPE]: (): DeepReadonly<SpecConfig> => EVENTS.SEARCH.SPEC,
  [EVENTS.VIEW_ITEM.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.VIEW_ITEM.SPEC,
  [EVENTS.SELECT_ITEM.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.SELECT_ITEM.SPEC,
  [EVENTS.VIEW_CART.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.VIEW_CART.SPEC,
  [EVENTS.REMOVE_FROM_CART.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.REMOVE_FROM_CART.SPEC,
  [EVENTS.CG_CLICKS.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.CG_CLICKS.SPEC,
  [EVENTS.PG_CLICKS.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.PG_CLICKS.SPEC,
  [EVENTS.LOGIN.INT_TYPE]: (): DeepReadonly<SpecConfig> => EVENTS.LOGIN.SPEC,
  [EVENTS.REGISTER.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.REGISTER.SPEC,
  [EVENTS.VIDEO_START.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.VIDEO_START.SPEC,
  [EVENTS.VIDEO_IN_PROGRESS.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.VIDEO_IN_PROGRESS.SPEC,
  [EVENTS.VIDEO_COMPLETE.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.VIDEO_COMPLETE.SPEC,
  [EVENTS.FACET_CLICK.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.FACET_CLICK.SPEC,
  [EVENTS.ADD_COUPON.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.ADD_COUPON.SPEC,
  [EVENTS.NEW_TAB_OPENED.INT_TYPE]: (): DeepReadonly<SpecConfig> =>
    EVENTS.NEW_TAB_OPENED.SPEC
};

export const mapSpec = (detail: AnalyticsEventDetail): AnalyticsEventDetail => {
  return {
    ...detail,
    spec: eventMapper?.[detail.type]?.(detail.data)
  };
};
