import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import useForkRef from "../utils/useForkRef";
import debounce from "../utils/debounce";
import { TextareaAutosizeProps } from "./types";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";

/*
 ! Refer to the README.md in the TailwindTextField component for guidelines on styling, adding styles, and debugging.
  ! Avoid using "!important" in className props, passed classes, or componentStyles within the component.
*/

const componentStyles = {
  /* Styles applied to hidden textarea */
  shadow: "invisible absolute overflow-hidden h-0 top-0 left-0 translate-z-0"
};

function getStyleValue(
  computedStyle: CSSStyleDeclaration,
  property: string
): number {
  return parseInt(computedStyle[property], 10) || 0;
}

//use useLayoutEffect when on browser as window object is defined on browser
// else use useEffect while SSR
const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : useEffect;

export const TextareaAutosize = forwardRef<
  HTMLTextAreaElement,
  TextareaAutosizeProps
>((props, ref) => {
  let { onChange, rows, rowsMax, rowsMin = 1, style, value, ...other } = props;

  rowsMin = rows || rowsMin;

  //if value is given then isControlled is true else is false
  const isControlled = value != null;

  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  //useForkRef handles both function ref and object ref
  const handleRef = useForkRef(ref, inputRef);

  const shadowRef = useRef<HTMLTextAreaElement | null>(null);

  //use to count renders
  const renders = useRef(0);

  const [state, setState] = useState({} as any);

  const syncHeight = useCallback(
    function () {
      //inputRef.current give reference to the input element
      const input = inputRef.current;

      //comuted style are the final style that browser applies to the element
      const computedStyle = window.getComputedStyle(input!);

      const inputShallow = shadowRef.current;

      inputShallow!.style.width = computedStyle.width;
      inputShallow!.value = input!.value || props.placeholder || "x";

      //checks whether the last character of the inputShallow.value is "\n" and if true then add space at the end of inputShallow.value
      if (inputShallow!.value.slice(-1) === "\n") {
        inputShallow!.value += " ";
      }

      const boxSizing = computedStyle["box-sizing"];
      const padding =
        getStyleValue(computedStyle, "padding-bottom") +
        getStyleValue(computedStyle, "padding-top");

      const border =
        getStyleValue(computedStyle, "border-bottom-width") +
        getStyleValue(computedStyle, "border-top-width");

      const innerHeight = inputShallow!.scrollHeight - padding;

      inputShallow!.value = "x";
      const singleRowHeight = inputShallow!.scrollHeight - padding;

      let outerHeight = innerHeight;

      if (rowsMin)
        outerHeight = Math.max(Number(rowsMin) * singleRowHeight, outerHeight);

      if (rowsMax)
        outerHeight = Math.min(Number(rowsMax) * singleRowHeight, outerHeight);

      outerHeight = Math.max(outerHeight, singleRowHeight);

      const outerHeightStyle =
        outerHeight + (boxSizing === "border-box" ? padding + border : 0);

      const overflow = Math.abs(outerHeight - innerHeight) <= 1;

      setState((prevState: any) => {
        if (
          renders.current < 20 &&
          ((outerHeightStyle > 0 &&
            Math.abs((prevState.outerHeightStyle || 0) - outerHeightStyle) >
              1) ||
            prevState.overflow !== overflow)
        ) {
          renders.current += 1;
          return {
            overflow: overflow,
            outerHeightStyle: outerHeightStyle
          };
        }

        //this prevents infinite loop of renders and give error asa number of renders reaches to 20
        if (process.env.NODE_ENV !== "production") {
          if (renders.current === 20) {
            console.error(
              [
                "Tailwind TextareaAutosize: Too many re-renders. The layout is unstable.",
                "TextareaAutosize limits the number of renders to prevent an infinite loop."
              ].join("\n")
            );
          }
        }
        return prevState;
      });
    },
    [rowsMax, rowsMin, props.placeholder]
  );

  //calculating syncHeight on window resizing
  useEffect(() => {
    const handleResize = debounce(() => {
      renders.current = 0;
      syncHeight();
    });

    window.addEventListener("resize", handleResize);

    return () => {
      handleResize.clear();
      window.removeEventListener("resize", handleResize);
    };
  }, [syncHeight]);

  useEnhancedEffect(() => {
    syncHeight();
  });

  useEffect(() => {
    renders.current = 0;
  }, [value]);

  const handleChange = (event: any) => {
    renders.current = 0;

    if (!isControlled) syncHeight();

    if (onChange) onChange(event);
  };

  /*
  ! ⚠️ DO NOT change the order of styles inside the `className` prop passed to `textarea` in the return statement.
 ! Style coming next will override common attributes of the previous style. Thus changing order can result in unexpected behaviour
 */
  return (
    <>
      <textarea
        value={value}
        onChange={handleChange}
        ref={handleRef}
        rows={Number(rowsMin)}
        style={{
          height: state.outerHeightStyle,
          overflow: state.overflow ? "hidden" : undefined,
          ...style
        }}
        {...other}
      />
      <textarea
        aria-hidden="true"
        className={twMerge(clsx(props.className, componentStyles.shadow))}
        readOnly={true}
        ref={shadowRef}
        tabIndex={-1}
        style={{
          ...style
        }}
      />
    </>
  );
});
