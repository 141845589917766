import { useQuery } from "@apollo/client";
import { queryGetCartIdForSession } from "@RHCommerceDev/graphql-client/queries/session";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";
import { useCurrentCartId } from "@RHCommerceDev/hooks-atoms/useCurrentCartId";

export function useCartIdForSession() {
  const currentCartId = useCurrentCartId();
  const env = useEnv();
  const { data, loading, error, refetch } = useQuery<
    {
      getCartIdForSession: {
        cartId?: string;
        userId?: string;
        sessionId?: string;
      };
    },
    null
  >(queryGetCartIdForSession, {
    fetchPolicy: "cache-and-network",
    skip: !yn(env.FEATURE_CART_ID_FOR_SESSION)
  });
  return {
    cartId: yn(env.FEATURE_CART_ID_FOR_SESSION)
      ? data?.getCartIdForSession?.cartId
      : currentCartId,
    data,
    loading,
    error,
    refetch
  };
}
